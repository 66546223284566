import React, {
    useState,
    useRef,
    useCallback,
    useReducer,
    createContext,
    ReactNode,
    useContext,
    Dispatch,
    useEffect,
    useMemo,
    ChangeEvent,
} from 'react'
import 'reactflow/dist/style.css'
import neo4j, { Driver, Session } from 'neo4j-driver'
import { v4 as uuidv4 } from 'uuid'

import {
    HStack,
    VStack,
    Heading,
    Button,
    Text,
    Badge,
    Input,
    IconButton,
    Select,
    Divider,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { IWordLevelAnalysis } from '../components/Reader/WordLevelAnalysis'
import { convertEncodingToUnicode } from '../components/Decoder'
import { ArrowRightIcon, CloseIcon, SearchIcon } from '@chakra-ui/icons'

interface IRecords {
    source: {
        properties: {
            qoroyoId: string
            title: string
            type: string
            encoding: string
            seo: string
        }
    }
    superorganisms: {
        superorganism: {
            properties: {
                chapter: number
                number: number
                qoroyoId: string
                type: string
                book_name: string
            }
        }
        organisms: {
            molecules: {
                molecule: {
                    properties: {
                        order: number
                    }
                }
                atoms: {
                    properties: IWordLevelAnalysis & {
                        order: number
                        functions_sp: string
                    }
                }[]
            }[]
            organism: {
                properties: {
                    body: string
                    encoding: string
                }
            }
        }[]
    }[]
}

const SearchResult = (props: {
    text: string
    encoding: string
    molecules: {
        molecule: {
            properties: {
                order: number
            }
        }
        atoms: {
            properties: IWordLevelAnalysis & {
                order: number
                functions_sp: string
            }
        }[]
    }[]
}) => {
    const arksParsed = useMemo(() => {
        const data = props.encoding.split(' ').map((ark) => {
            return {
                body: ark,
                words: ark
                    .split('-')
                    .map((w) => ({ body: w, highlight: false, sp: '' })),
            }
        })

        props.molecules.map((molecule) => {
            molecule.atoms.map((atom) => {
                console.log(atom.properties.functions_sp)
                data[
                    neo4j.integer.toNumber(
                        molecule.molecule.properties.order
                    ) as number
                ]['words'][neo4j.integer.toNumber(atom.properties.order)][
                    'highlight'
                ] = true

                if (atom.properties.functions_sp) {
                    data[
                        neo4j.integer.toNumber(
                            molecule.molecule.properties.order
                        ) as number
                    ]['words'][neo4j.integer.toNumber(atom.properties.order)][
                        'sp'
                    ] = atom.properties.functions_sp
                }

                return atom
            })
            return molecule
        })

        return data
    }, [props.encoding, props.molecules])

    console.log(arksParsed)

    return (
        <HStack dir="rtl" w="100%" maxW="container.lg" flexWrap={'wrap'}>
            {arksParsed.map((ark) => {
                return (
                    <HStack dir="rtl" spacing={0} display="inline">
                        {ark.words.map((word) => {
                            let color = 'green'

                            if (word.sp === 'prep') {
                                color = 'orange'
                            }

                            if (word.sp === 'subs') {
                                color = 'purple'
                            }

                            if (word.sp === 'verb') {
                                color = 'green'
                            }

                            if (word.highlight) {
                                return (
                                    <Text
                                        fontSize={'4xl'}
                                        display="inline"
                                        style={{ marginInlineStart: 0 }}
                                        fontFamily={'SertoJerusalem'}
                                        as="mark"
                                        background="transparent"
                                        border={`2px solid ${color}`}
                                        borderRadius={'0.2rem'}
                                    >
                                        {convertEncodingToUnicode(word.body)}
                                    </Text>
                                )
                            }

                            return (
                                <Text
                                    fontSize={'3xl'}
                                    as="span"
                                    fontFamily={'SertoJerusalem'}
                                    display="inline"
                                    style={{ marginInlineStart: 0 }}
                                >
                                    {convertEncodingToUnicode(word.body)}
                                </Text>
                            )
                        })}
                    </HStack>
                )
            })}
        </HStack>
    )
}
const buildFilter = (
    index: number,
    type: string,
    text: string,
    multipleItems = false
) => {
    let q = `WHERE a${index}.functions_sp = "${type}"`

    if (type === 'prep' && text.length) {
        q = q + ` and a${index}.code = "${text}"`
    }

    if ((type === 'subs' || type === 'verb') && text.length) {
        q = q + ` and "${text}" in a${index}.lexeme`
    }

    if (multipleItems) {
        q = q + ` and a${index}.order = ${index}`
    }

    return q
}
const buildQuery = (items: Item[]) => {
    if (items.length === 0) {
        return 'No items to build query'
    }

    const atomMatches = items
        .map(
            (item, index) => `
      MATCH (m)-[:CONTAINS]->(a${index}:Atom)
      ${buildFilter(index, item.type, item.text, items.length > 1)}
    `
        )
        .join('\n')

    const siblingAtoms = items.map((_, index) => `a${index}`).join(', ')

    return `
      MATCH (s:Source)-[:CONTAINS]->(so:Superorganism)-[:CONTAINS]->(o:Organism)-[:CONTAINS]->(m:Molecule)
      WITH s, so, o, m
      ${atomMatches} and NOT s.seo = "tgpsalms"
   
      WITH s, so, o, m, [${siblingAtoms}] AS allAtoms
      LIMIT 25
      WITH s, so, o, collect({
          molecule: m,
          atoms: allAtoms
      }) AS molecules
      WITH s, so, collect({
          organism: o,
          molecules: molecules
      }) AS organisms
      WITH s, collect({
          superorganism: so,
          organisms: organisms
      }) AS superorganisms
      RETURN {
          source: s,
          superorganisms: superorganisms
      } AS value 
    `
}

const Neo4jComponent: React.FC = () => {
    const [data, setData] = useState<IRecords[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    const [items, setItems] = useState<Item[]>([])

    const handleClick = (type: string) => {
        setItems([...items, { type, text: '', param: 'default', id: uuidv4() }])
    }

    const handleInputChange = (index: number, text: string) => {
        const newItems = [...items]
        newItems[index].text = text
        setItems(newItems)
    }

    const handleSelectChange = (index: number, param: string) => {
        const newItems = [...items]
        newItems[index].param = param
        setItems(newItems)
    }

    const handleCollect = () => {
        const query = buildQuery(items)
        console.log('Q', query)
        fetchData(query)
    }

    const handleRemove = (id: string) => {
        console.log(id)
        const newItems = items.filter((item) => item.id !== id)
        setItems(newItems)
    }

    const fetchData = useCallback(async (query: string) => {
        const driver: Driver = neo4j.driver(
            'bolt://localhost:7687',
            neo4j.auth.basic('neo4j', 'Im@gine12')
        )

        const session: Session = driver.session()
        try {
            const result = await session.run(query)
            const records = result.records.map((r) => r.get('value'))
            console.log(records)
            setData(records as IRecords[])
        } catch (error) {
            console.error('Error querying Neo4j:', error)
        } finally {
            await session.close()
            setLoading(false)
        }

        driver.close()
    }, [])

    // if (word.sp === 'prep') {
    //     color = 'orange'
    // }

    // if (word.sp === 'subs') {
    //     color = 'purple'
    // }

    // if (word.sp === 'verb') {
    //     color = 'green'
    // }

    const hexToRgb = (hex: string) => {
        // Remove the leading '#' if present
        hex = hex.replace(/^#/, '')

        // Convert 3-digit hex to 6-digit hex
        if (hex.length === 3) {
            hex = hex
                .split('')
                .map((char) => char + char)
                .join('')
        }

        // Extract the red, green, and blue components
        const bigint = parseInt(hex, 16)
        const r = (bigint >> 16) & 255
        const g = (bigint >> 8) & 255
        const b = bigint & 255

        return { r, g, b }
    }

    const generateBoxShadow = (type: string) => {
        let hexColor = ''

        if (type === 'prep') {
            hexColor = '#dd6b03'
        }

        if (type === 'subs') {
            hexColor = '#805ad5'
        }

        if (type === 'verb') {
            hexColor = '#38a169'
        }

        const rgbColor = hexToRgb(hexColor)

        const dynamicBoxShadow = `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.4) 0px 2px 4px, rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.3) 0px 7px 13px -3px, rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.2) 0px -3px 0px inset`

        return dynamicBoxShadow
    }

    const getBackgroundColorBasedOnType = (type: string) => {
        if (type === 'prep') {
            return 'orange'
        }

        if (type === 'subs') {
            return 'purple'
        }

        if (type === 'verb') {
            return 'green'
        }
    }

    return (
        <VStack w="100%" spacing={5}>
            <HStack>
                <Button
                    onClick={() => handleClick('prep')}
                    colorScheme={'orange'}
                    size={'sm'}
                >
                    Prep
                </Button>
                <Button
                    colorScheme={'green'}
                    onClick={() => handleClick('verb')}
                    size={'sm'}
                >
                    Verb
                </Button>
                <Button
                    colorScheme={'purple'}
                    onClick={() => handleClick('subs')}
                    size={'sm'}
                >
                    Subs
                </Button>
                <Button
                    variant={'ghost'}
                    onClick={() => setItems([])}
                    size={'sm'}
                >
                    Clear
                </Button>
            </HStack>
            <Divider />
            <HStack
                flexWrap="wrap"
                w="100%"
                justifyContent={'center'}
                style={{ marginTop: '2rem' }}
            >
                {items.map((item, index) => (
                    <>
                        <HStack
                            key={item.id}
                            borderColor={getBackgroundColorBasedOnType(
                                item.type
                            )}
                            borderWidth={'1px'}
                            borderStyle={'solid'}
                            padding="3"
                            style={{ marginBottom: '1rem' }}
                            borderRadius={'1rem'}
                            boxShadow={generateBoxShadow(item.type)}
                        >
                            <Badge
                                borderRadius={'50%'}
                                padding={'0.25rem 0.75rem'}
                                color={'white'}
                                bgColor={getBackgroundColorBasedOnType(
                                    item.type
                                )}
                                fontSize={'sm'}
                            >
                                {index + 1}
                            </Badge>
                            <Select
                                size="sm"
                                value={item.param}
                                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                                    handleSelectChange(index, e.target.value)
                                }
                            >
                                <option value="default">Default</option>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                            </Select>
                            <Input
                                size="sm"
                                type="text"
                                value={item.text}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    handleInputChange(index, e.target.value)
                                }
                            />
                            <IconButton
                                size="sm"
                                onClick={() => handleRemove(item.id)}
                                icon={<CloseIcon />}
                                aria-label={'Remove filter'}
                            >
                                Remove
                            </IconButton>
                        </HStack>
                        {index !== items.length - 1 && <ArrowRightIcon />}
                    </>
                ))}
            </HStack>
            <Divider />
            <HStack w="100%" justifyContent={'center'}>
                <Button
                    leftIcon={<SearchIcon />}
                    onClick={() => handleCollect()}
                >
                    Search
                </Button>
            </HStack>
            <Heading textAlign="center" color={'twitter.800'}>
                Results:
            </Heading>
            {data.map((record) => (
                <VStack w="100%" spacing={5}>
                    {record.source.properties.title}
                    <Heading fontSize={'2xl'} color={'twitter.800'}>
                        {record.source.properties.title}
                    </Heading>
                    {record.superorganisms.map((superorganism) => (
                        <VStack
                            w="100%"
                            padding={'1rem'}
                            border="1px solid var(--chakra-colors-gray-200)"
                            borderRadius={'1rem'}
                        >
                            {superorganism.organisms.map((organism) => (
                                <SearchResult
                                    text={organism.organism.properties.body}
                                    encoding={
                                        organism.organism.properties.encoding
                                    }
                                    molecules={organism.molecules}
                                />
                            ))}
                            <HStack w="100%" justifyContent={'flex-end'}>
                                <Badge fontSize={'sm'}>
                                    {
                                        superorganism.superorganism.properties
                                            .book_name
                                    }
                                </Badge>
                                <Badge fontSize={'sm'}>
                                    {neo4j.integer.toNumber(
                                        superorganism.superorganism.properties
                                            .chapter
                                    )}
                                </Badge>
                                <Badge fontSize={'sm'}>
                                    {neo4j.integer.toNumber(
                                        superorganism.superorganism.properties
                                            .number
                                    )}
                                </Badge>
                            </HStack>
                        </VStack>
                    ))}
                </VStack>
            ))}
        </VStack>
    )
}

interface Item {
    id: string
    type: string
    text: string
    param: string
}

const SearchFlow = () => {
    return (
        <HStack alignItems="flex-start" width="100%">
            <VStack
                spacing="5"
                background="white"
                padding="24px"
                borderRadius="24px"
                minWidth={'container.md'}
                minHeight={'container.md'}
                width="100%"
            >
                <Heading textAlign="center" color={'twitter.800'}>
                    Search
                </Heading>

                <Neo4jComponent />
            </VStack>
        </HStack>
    )
}

const Search = () => {
    return <SearchFlow />
}

export default Search
