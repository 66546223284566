import {
    HStack,
    PopoverTrigger,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    Text,
    Portal,
    VStack,
    Tag,
    Divider,
    PopoverFooter,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { useMemo } from 'react'
import { ILexemeComparatorOption } from '../../pages/Reader'
import {
    convertCalToUnicode,
    convertEncodingToUnicode,
    convertGeezEncodingToUnicode,
    convertHebrewEncodingToUnicode,
} from '../Decoder'

const arkProperties = {
    ps: 'person',
    nu: 'number',
    gn: 'gender',
    sp: 'part-of-speech',
    st: 'state',
    vo: 'voice',
    vs: 'verbal stem',
    vt: 'verbal tense',
    ls: 'ls',
}

interface IMorpheme {
    lexeme: string[]
    pfm?: string[]
    pfx?: string[]
    vbs?: string[]
    vbe?: string[]
    nme?: string[]
    emf?: string[]
    pm?: string[]
    vpm?: string
}

export interface IWordLevelAnalysis {
    code: string
    surface: string
    gloss: string
    morphemes: IMorpheme
    functions: { [K in keyof typeof arkProperties]?: string }
    realisation: string
    lexeme?: string[]
    sharedLexeme?: boolean
}

export type IWordLevelAnalysisResponse = Array<Array<Array<IWordLevelAnalysis>>>

const PopoverContainer = styled(HStack)`
    display: inline-flex;
`

const DisplayGeezWords: React.FC<{ ark: IWordLevelAnalysis[] }> = ({ ark }) => {
    let combinedRealisations = ''

    ark.map((entry) => (combinedRealisations += entry.realisation))

    return (
        <Text fontFamily={'Noto Sans Ethiopic'} fontSize={21}>
            {convertGeezEncodingToUnicode(combinedRealisations)}
        </Text>
    )
}

const WordLevelAnalysis: React.FC<{
    enableWesternScript: boolean
    text: string
    wla: Array<Array<IWordLevelAnalysis>>
    encoding: 'syriacetcbc' | 'aramaiccal' | 'geezetcbc' | 'hebrewetcbc'
    lexemeComparator: ILexemeComparatorOption
}> = ({ enableWesternScript, text, wla, encoding, lexemeComparator }) => {
    const textBrokenIntoChunks = useMemo(() => {
        return text.split(' ')
    }, [text])

    if (wla && !wla.length) {
        return (
            <Text
                fontFamily={
                    enableWesternScript ? 'SertoJerusalem' : 'EstrangeloEdessa'
                }
                fontSize={enableWesternScript ? '3xl' : '2xl'}
                display="inline-flex"
            >
                {convertEncodingToUnicode(text)}
            </Text>
        )
    }

    let fontFamily = 'SertoJerusalem'
    let fontSize = '3xl'

    if (encoding === 'syriacetcbc') {
        if (!enableWesternScript) {
            fontFamily = 'EstrangeloEdessa'
            fontSize = '2xl'
        }
    }

    if (encoding === 'aramaiccal' || encoding === 'hebrewetcbc') {
        fontFamily = 'Noto Sans Hebrew'
        fontSize = '1.6rem'
    }

    // --chakra-colors-gray-800

    let primaryFontColor = 'var(--chakra-colors-gray-800)'
    let secondaryFontColor = 'var(--chakra-colors-gray-800)'

    if (lexemeComparator === 'both') {
        primaryFontColor = '#3e6869'
        secondaryFontColor = 'darkred'
    }

    if (lexemeComparator === 'conv') {
        secondaryFontColor = 'var(--chakra-colors-gray-400)'
    }

    if (lexemeComparator === 'div') {
        primaryFontColor = 'var(--chakra-colors-gray-400)'
    }

    return (
        <>
            {textBrokenIntoChunks.map((ark, arkIndex) => {
                return (
                    <PopoverContainer
                        key={`popover-container-${ark}-${arkIndex}`}
                    >
                        <Popover trigger="hover">
                            <PopoverTrigger>
                                <HStack display={'inline'} mb="2">
                                    {encoding !== 'geezetcbc' &&
                                        wla &&
                                        wla[arkIndex].map((w, wIndex) => (
                                            <Text
                                                key={w.code + '-' + wIndex}
                                                as="span"
                                                fontFamily={fontFamily}
                                                fontSize={fontSize}
                                                color={
                                                    w.sharedLexeme
                                                        ? primaryFontColor
                                                        : secondaryFontColor
                                                }
                                                display="inline"
                                                style={{ marginInlineStart: 0 }}
                                            >
                                                {encoding === 'aramaiccal' &&
                                                    convertCalToUnicode(
                                                        w.realisation
                                                    )}
                                                {encoding === 'syriacetcbc' &&
                                                    convertEncodingToUnicode(
                                                        w.code
                                                    )}
                                                {encoding === 'hebrewetcbc' &&
                                                    convertHebrewEncodingToUnicode(
                                                        w.realisation
                                                    )}
                                            </Text>
                                        ))}

                                    {encoding === 'geezetcbc' && wla && (
                                        <DisplayGeezWords ark={wla[arkIndex]} />
                                    )}
                                </HStack>
                            </PopoverTrigger>
                            <Portal>
                                <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverHeader>
                                        ETCBC Analysis
                                    </PopoverHeader>
                                    <PopoverBody pb="4">
                                        {wla &&
                                            wla[arkIndex]?.map((w, wIndex) => {
                                                return (
                                                    <VStack
                                                        key={`popover-body-${w.code}-${wIndex}`}
                                                    >
                                                        <VStack
                                                            width="100%"
                                                            spacing={1}
                                                        >
                                                            <HStack width="100%">
                                                                <Tag>
                                                                    word:{' '}
                                                                </Tag>
                                                                {(encoding ===
                                                                    'aramaiccal' ||
                                                                    encoding ===
                                                                        'hebrewetcbc') && (
                                                                    <Text
                                                                        fontFamily={
                                                                            'Hebrew Noto Sans'
                                                                        }
                                                                        fontSize="1.2rem"
                                                                    >
                                                                        {convertCalToUnicode(
                                                                            w.code
                                                                        )}
                                                                    </Text>
                                                                )}
                                                                {encoding ===
                                                                    'syriacetcbc' && (
                                                                    <Text
                                                                        fontFamily={
                                                                            enableWesternScript
                                                                                ? 'SertoJerusalem'
                                                                                : 'EstrangeloEdessa'
                                                                        }
                                                                        fontSize="2xl"
                                                                    >
                                                                        {convertEncodingToUnicode(
                                                                            w.code
                                                                        )}
                                                                    </Text>
                                                                )}
                                                                {encoding ===
                                                                    'geezetcbc' && (
                                                                    <Text>
                                                                        {convertGeezEncodingToUnicode(
                                                                            w.realisation
                                                                        )}
                                                                    </Text>
                                                                )}
                                                            </HStack>
                                                            <HStack width="100%">
                                                                <Tag>
                                                                    gloss:{' '}
                                                                </Tag>
                                                                <Text
                                                                    fontSize="sm"
                                                                    fontFamily={
                                                                        'Amiko'
                                                                    }
                                                                >
                                                                    {w.gloss}
                                                                </Text>
                                                            </HStack>
                                                            {w.functions &&
                                                                Object.keys(
                                                                    w.functions
                                                                ).map(
                                                                    (
                                                                        f,
                                                                        fIndex
                                                                    ) =>
                                                                        arkProperties[
                                                                            f as keyof typeof arkProperties
                                                                        ] && (
                                                                            <HStack
                                                                                width="100%"
                                                                                key={`function-${f}-${fIndex}`}
                                                                            >
                                                                                <Tag>
                                                                                    {
                                                                                        arkProperties[
                                                                                            f as keyof typeof arkProperties
                                                                                        ]
                                                                                    }
                                                                                </Tag>
                                                                                <Text
                                                                                    fontSize="sm"
                                                                                    fontFamily={
                                                                                        'Amiko'
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        w
                                                                                            .functions[
                                                                                            f as keyof typeof arkProperties
                                                                                        ]
                                                                                    }
                                                                                </Text>
                                                                            </HStack>
                                                                        )
                                                                )}
                                                        </VStack>
                                                        {wla[arkIndex].length >
                                                            1 &&
                                                            wla[arkIndex]
                                                                .length -
                                                                1 !==
                                                                wIndex && (
                                                                <Divider
                                                                    mt="4"
                                                                    variant="dashed"
                                                                    style={{
                                                                        borderColor:
                                                                            'var(--chakra-colors-gray-500)',
                                                                        marginBottom:
                                                                            '0.5rem',
                                                                    }}
                                                                />
                                                            )}
                                                    </VStack>
                                                )
                                            })}
                                    </PopoverBody>
                                    {encoding === 'aramaiccal' && (
                                        <PopoverFooter>
                                            <Text
                                                fontSize={'sm'}
                                                color="gray.500"
                                            >
                                                Initial data was derived from
                                                CAL. Courtesy of Steve Kaufman.
                                            </Text>
                                        </PopoverFooter>
                                    )}
                                </PopoverContent>
                            </Portal>
                        </Popover>
                    </PopoverContainer>
                )
            })}
        </>
    )
}

export default WordLevelAnalysis
