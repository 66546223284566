import {
    Avatar,
    Container,
    Divider,
    Flex,
    Heading,
    HStack,
    Img,
    Tag,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react'
import { Outlet, useNavigate } from 'react-router-dom'
import Logo from '../components/Logo'
import { Roles } from '../contexts/Auth'
import Approve from '../icons/Approve'
import Bookshelf from '../icons/Bookshelf'
import Invite from '../icons/Invite'
import Logout from '../icons/Logout'
import { NavButton } from '../components/Buttons'
import { useCallback, useEffect, useState } from 'react'
import { supabase } from '../supabaseClient'
import { useProfile } from '../contexts/Profile'
import Search from '../icons/Search'
import Progress from '../icons/Progress'

const Dashboard = () => {
    const { role, profile, resetProfile } = useProfile()
    const navigate = useNavigate()
    const [pendingApprovals, setPendingApprovals] = useState(0)
    const toast = useToast()
    const toastId = 'dashboard-nav-toast'

    const getNumberOfPendingApprovals = useCallback(async () => {
        const { data, error } = await supabase
            .from('get_pendingapproval_count')
            .select()
            .limit(1)
            .single()

        if (data) {
            setPendingApprovals(data.count)
        }

        if (error && !toast.isActive(toastId)) {
            toast({
                id: toastId,
                title: 'Issue',
                description: 'Coulld not retrieve number of pending approvals',
                status: 'error',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top',
            })
        }
    }, [toast])

    useEffect(() => {
        const channel = supabase.channel('dashboard-nav')

        channel
            .on(
                'postgres_changes',
                {
                    event: 'INSERT',
                    schema: 'public',
                    table: 'etcbc_encoding_pendingapproval',
                },
                () => getNumberOfPendingApprovals()
            )
            .on(
                'postgres_changes',
                {
                    event: 'DELETE',
                    schema: 'public',
                    table: 'etcbc_encoding_pendingapproval',
                },
                () => getNumberOfPendingApprovals()
            )
            .subscribe()

        getNumberOfPendingApprovals()

        return () => {
            supabase.removeChannel(channel)
        }
    }, [getNumberOfPendingApprovals])

    return (
        <Flex
            background="gray.200"
            minWidth="100vw"
            minHeight="100vh"
            justifyContent="center"
        >
            <Container
                padding={{
                    base: '6px',
                    md: '16px',
                }}
                maxW="container.xl"
            >
                <HStack
                    spacing={{
                        base: 0,
                        md: 2,
                    }}
                    alignItems="flex-start"
                    width="100%"
                >
                    <VStack
                        background="white"
                        padding="1.5rem 3.5rem"
                        borderRadius="24px"
                        alignItems="flex-start"
                        display={{
                            base: 'none',
                            md: 'flex',
                        }}
                        position="sticky"
                        top="0"
                    >
                        <VStack spacing={0}>
                            <Logo
                                boxSize="64px"
                                fill="teal"
                                alignSelf="center"
                            />
                            <Heading color="teal">Qoroyo</Heading>
                        </VStack>
                        <VStack style={{ marginTop: '1rem' }}>
                            {/* <NavButton
                                minWidth="100%"
                                onClick={() => {
                                    navigate('/search')
                                }}
                                leftIcon={<Search />}
                                variant="ghost"
                                justifyContent="flex-start"
                            >
                                Search
                            </NavButton> */}
                            <NavButton
                                minWidth="100%"
                                onClick={() => {
                                    navigate('/texts')
                                }}
                                leftIcon={<Bookshelf />}
                                variant="ghost"
                                justifyContent="flex-start"
                            >
                                Texts
                            </NavButton>
                            <NavButton
                                minWidth="100%"
                                onClick={() => {
                                    navigate('/progress')
                                }}
                                leftIcon={<Progress />}
                                variant="ghost"
                                justifyContent="flex-start"
                            >
                                Progress
                            </NavButton>
                            {role === Roles.Admin && (
                                <>
                                    <NavButton
                                        minWidth="100%"
                                        leftIcon={<Approve />}
                                        variant="ghost"
                                        onClick={() => {
                                            navigate('/approve')
                                        }}
                                        justifyContent="flex-start"
                                    >
                                        Approve
                                        {pendingApprovals > 0 && (
                                            <Tag colorScheme="pink" size="sm">
                                                {pendingApprovals}
                                            </Tag>
                                        )}
                                    </NavButton>
                                    <NavButton
                                        minWidth="100%"
                                        leftIcon={<Invite />}
                                        variant="ghost"
                                        onClick={() => {
                                            navigate('/invite')
                                        }}
                                        justifyContent="flex-start"
                                    >
                                        Invite
                                    </NavButton>
                                </>
                            )}
                            <NavButton
                                minWidth="100%"
                                onClick={async () => {
                                    console.log('logging out')
                                    resetProfile()
                                    await supabase.auth.signOut()
                                    navigate('/login', { replace: true })
                                }}
                                leftIcon={<Logout />}
                                variant="ghost"
                                justifyContent="flex-start"
                            >
                                Logout
                            </NavButton>
                            <Divider />
                            {profile && (
                                <HStack
                                    spacing="2"
                                    style={{
                                        marginTop: '1rem',
                                    }}
                                >
                                    <Avatar size="xs" />
                                    <Text whiteSpace={'pre'}>
                                        {`${profile.first_name} ${profile.last_name}`}
                                    </Text>
                                </HStack>
                            )}
                            <VStack
                                spacing={0}
                                style={{
                                    mixBlendMode: 'luminosity',
                                    marginTop: '2rem',
                                    opacity: 0.4,
                                }}
                            >
                                <Text size="xs" color={'gray'} fontSize="xs">
                                    A project by:{' '}
                                </Text>
                                <Img src="/etcbclogo.png" maxW={'5rem'} />
                            </VStack>
                        </VStack>
                    </VStack>
                    <Outlet />
                </HStack>
            </Container>
        </Flex>
    )
}

export default Dashboard
