import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Divider,
    FormControl,
    FormHelperText,
    FormLabel,
    Heading,
    Hide,
    HStack,
    Radio,
    RadioGroup,
    Switch,
    Tag,
    VStack,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import Cascader from 'antd/es/cascader'
import {
    createContext,
    Dispatch,
    useCallback,
    useEffect,
    useReducer,
    useState,
} from 'react'
import { useParams, Link, useSearchParams } from 'react-router-dom'
import ChevronRight from '../icons/ChevronRight'
import { supabase } from '../supabaseClient'
import Lookup from '../components/Lookup'
import axios from 'axios'
import EtcbcLexicon from '../components/EtcbLexicon'
import { Verse } from '../components/Reader/Verse'
import { IWordLevelAnalysis } from '../components/Reader/WordLevelAnalysis'

export enum ReaderActions {
    LOOKUP_WORD = 'LOOKUP_WORD',
    SET_LOOKINGUP_WORD = 'SET_LOOKINGUP_WORD',
}

type ReaderAction =
    | {
          type: ReaderActions.LOOKUP_WORD
          dispatch: {
              word: string
              timestamp: string
          }
      }
    | {
          type: ReaderActions.SET_LOOKINGUP_WORD
          dispatch: boolean
      }

interface ReaderState {
    lookupWord: {
        word: string
        timestamp: string
    }
    lookingupWord: boolean
}

const readerReducer = (state: ReaderState, action: ReaderAction) => {
    switch (action.type) {
        case ReaderActions.LOOKUP_WORD:
            return {
                ...state,
                lookupWord: action.dispatch,
            }
        case ReaderActions.SET_LOOKINGUP_WORD:
            return {
                ...state,
                lookingupWord: action.dispatch,
            }
    }
}

const readerInitialValues = {
    lookupWord: {
        word: '',
        timestamp: '',
    },
    lookingupWord: false,
}

export const ReaderContext = createContext<{
    state: ReaderState
    dispatch: Dispatch<ReaderAction>
}>({
    state: readerInitialValues,
    dispatch: () => {
        return
    },
})

export const H2 = styled.h2`
    font-size: 30px;
    font-weight: 300;
    color: #222;
    letter-spacing: 1px;
    text-transform: uppercase;

    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-template-rows: 27px 0;
    grid-gap: 20px;
    grid-row-gap: 0;
    align-items: center;

    &:after,
    &:before {
        content: ' ';
        display: block;
        border-top: 1px solid #aec5eb;
        position: relative;
        top: 0.5rem;
        height: 0.75rem;
        background-color: #fff;
    }
`

const Paragraph = styled.p<{ direction: 'left' | 'right' }>`
    direction: ${(props) => (props.direction === 'left' ? 'ltr' : 'rtl')};
    color: #3a405a;
    word-break: break-all;
`

export const SyriacWesternParagraph = styled(Paragraph)`
    font-family: SertoJerusalem;
    font-size: 2rem;
    line-height: 2;
`

export const SyriacEasternParagraph = styled(Paragraph)`
    font-family: EstrangeloEdessa;
    font-size: 1.8rem;
    line-height: 2;
`

const ExtraText = styled.section`
    background: floralwhite;
    border-radius: 24px;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const HebrewParagraph = styled(Paragraph)`
    font-family: 'Noto Sans Hebrew';
    font-size: 1.6rem;
    line-height: 2;

    span {
        font-family: 'Amiko', sans-serif;
        font-size: 1.4rem;
    }
`

export const GreekParagraph = styled(Paragraph)`
    font-family: 'SBLGreek';
    font-size: 1.6rem;
    line-height: 2;

    span {
        font-family: 'Amiko', sans-serif;
        font-size: 1.4rem;
    }
`

export const EncodingContainer = styled(ExtraText)`
    background: aliceblue;
    margin-bottom: 1rem;
    border-radius: 24px;
    padding: 1rem 2rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const OptionLabel = styled.span`
    font-family: 'Amiko';
    font-size: 1rem;
    padding: 0.25rem 1rem;
    background: white;
    border-radius: 24px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid var(--chakra-colors-gray-300);
`

export const CommentaryText = styled.p`
    font-family: 'Cormorant', serif;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--chakra-colors-gray-500);
`

interface Option {
    code: string
    name: string
    encoding?: string
    items?: Option[]
}

export interface EtcbcEncoding {
    id: string
    body: string
    suggestedChange: null | {
        id: string
        body: string
        user_id: {
            id: string
            first_name: string
            last_name: string
        }
        submitted: boolean
    }
}

export interface IVerse {
    id: string
    book_name: string
    chapter: number
    number: number
    verses: {
        id: string
        body: string
        text: { id: string; encoding: string; title: string }
    }[]
    encoding: EtcbcEncoding
}

export const TextAreaContainer = styled(HStack)`
    align-items: center;
    justify-content: flex-end;
    margin-top: 1rem;
    width: 100%;

    textarea {
        width: 100%;
        font-family: 'Barlow', monospace;
        background: transparent;
        border-radius: 1rem;
        resize: none;
        overflow: hidden;
        line-height: 2;
        word-spacing: 5px;
        padding: 0.5rem 1rem;

        &:focus {
            outline: 1px solid var(--chakra-colors-teal-400);
        }
    }
`

export const VerseTextContainer = styled(HStack)`
    p {
        word-break: break-word;
    }
`

export interface ITextInfo {
    textEncoding: string
    textId: string
    textTitle: string
    textSeo: string
    bookName: string
    bookSeo: string
    chapter: number
}

export interface IEncodingStore {
    [qoroyoId: string]: {
        [verseId: string]: string
    }
}

export interface IWLAStore {
    [qoroyoId: string]: {
        [verseId: string]: IWordLevelAnalysis[][]
    }
}

export type ILexemeComparatorOption = 'off' | 'conv' | 'div' | 'both'

function copyStructureFromEncodingToWLA(
    encodingStore: IEncodingStore
): IWLAStore {
    const wlaStore: IWLAStore = {}

    Object.entries(encodingStore).forEach(([qoroyoId, verseObj]) => {
        wlaStore[qoroyoId] = {}
        Object.keys(verseObj).forEach((verseId) => {
            wlaStore[qoroyoId][verseId] = []
        })
    })

    return wlaStore
}

function updateWlaStore(
    wlaStore: IWLAStore,
    verseValues: IWordLevelAnalysis[][][]
) {
    let index = 0

    Object.entries(wlaStore).forEach(([_, verseObj]) => {
        Object.keys(verseObj).forEach((verseId) => {
            if (index < verseValues.length) {
                verseObj[verseId] = verseValues[index++]
            }
        })
    })

    return wlaStore
}

export const constructRequestParams = (
    textEncoding: string,
    encodingTexts: string[]
) => {
    let languageParams = {
        language: 'syriac',
        version: 'SSI',
    }

    if (textEncoding === 'geezetcbc') {
        languageParams = {
            ...languageParams,
            language: 'geez',
            version: 'demo',
        }
    }

    if (textEncoding === 'hebrewetcbc') {
        languageParams = {
            ...languageParams,
            language: 'hebrew',
            version: 'synvar',
        }
    }

    const data = JSON.stringify({
        ...languageParams,
        request: 'analyse',
        mode: 'text',
        data: encodingTexts,
    })

    return data
}

const Reader = () => {
    const [state, dispatch] = useReducer(readerReducer, readerInitialValues)

    const { textId, bookId, chapterId } = useParams()

    const [mainTextInfo, setMainTextInfo] = useState<ITextInfo | null>(null)

    const [selectedOtherTexts, setSelectedOtherTexts] = useState<Option[]>([])

    const [verses, setVerses] = useState<IVerse[]>([] as IVerse[])

    const [options, setOptions] = useState<Option[]>([] as Option[])
    const [searchParams] = useSearchParams()

    const [enableWesternScript, setEnableWesternScript] = useState(true)
    const [displayEncoding, setDisplayEncoding] = useState<boolean>(
        searchParams.get('showEncoding') === 'true' ? true : false
    )

    const [showErrorsByDefault, setShowErrorsByDefault] =
        useState<boolean>(false)

    const [encodingsStore, setEncodingsStore] = useState<IEncodingStore>({})
    const [wlaStore, setWlaStore] = useState<IWLAStore>({})

    const [lexemeComparator, setLexemeComparator] =
        useState<ILexemeComparatorOption>('off')

    const loadWlaData = useCallback(async () => {
        const encodingTexts: string[] = []

        Object.entries(encodingsStore).forEach(([_, verseObj]) => {
            Object.entries(verseObj).forEach(([_, value]) => {
                encodingTexts.push(value)
            })
        })

        const data = constructRequestParams(
            mainTextInfo?.textEncoding || '',
            encodingTexts
        )

        const result = await axios.post('https://etcbc.vu.nl/api/wla', data)

        const newStore = copyStructureFromEncodingToWLA(encodingsStore)
        updateWlaStore(newStore, result.data.result)

        // const textIds = [
        //     mainTextInfo?.textId,
        //     ...selectedOtherTexts
        //         .filter(
        //             (text) =>
        //                 text.encoding === 'syriacetcbc' ||
        //                 text.encoding === 'geezetcbc' ||
        //                 text.encoding === 'aramaiccal'
        //         )
        //         .map((text) => text.code),
        // ]

        // if (chapterId) {
        //     const { data: wla } = await supabase
        //         .from('wla')
        //         .select(
        //             '*, verse!inner(id, text!inner(id)), bible_verse!inner(*)'
        //         )
        //         .match({ 'bible_verse.chapter': chapterId })
        //         .in('verse.text.id', textIds)

        //     wla?.map(
        //         (verse: {
        //             id: string
        //             verse: {
        //                 id: string
        //             }
        //             bible_verse: {
        //                 chapter: number
        //                 number: number
        //             }
        //             analyses: string
        //         }) => {
        //             if (
        //                 !(
        //                     `${verse.bible_verse.chapter}-${verse.bible_verse.number}` in
        //                     newStore
        //                 )
        //             ) {
        //                 newStore[
        //                     `${verse.bible_verse.chapter}-${verse.bible_verse.number}`
        //                 ] = {}
        //             }
        //             newStore[
        //                 `${verse.bible_verse.chapter}-${verse.bible_verse.number}`
        //             ][verse.verse.id] = JSON.parse(verse.analyses)
        //             return verse
        //         }
        //     )
        // }

        // function addSharedLexemeProperty(data: IWLAStore) {
        //     for (const parentKey in data) {
        //         for (const childKey in data[parentKey]) {
        //             data[parentKey][childKey].map((wordGroup) => {
        //                 wordGroup.map((word) => {
        //                     if (word.lexeme) {
        //                         word.lexeme.map((lexeme) => {
        //                             for (const siblingKey in data[parentKey]) {
        //                                 if (childKey !== siblingKey) {
        //                                     data[parentKey][siblingKey].map(
        //                                         (siblingWordGroup) => {
        //                                             siblingWordGroup.map(
        //                                                 (siblingWord) => {
        //                                                     if (
        //                                                         siblingWord.lexeme
        //                                                     ) {
        //                                                         siblingWord.lexeme.map(
        //                                                             (
        //                                                                 siblingWordLexeme
        //                                                             ) => {
        //                                                                 if (
        //                                                                     siblingWordLexeme.toUpperCase() ===
        //                                                                     lexeme.toUpperCase()
        //                                                                 ) {
        //                                                                     if (
        //                                                                         !word.sharedLexeme
        //                                                                     ) {
        //                                                                         word.sharedLexeme =
        //                                                                             true
        //                                                                     }

        //                                                                     siblingWord.sharedLexeme =
        //                                                                         true
        //                                                                 }

        //                                                                 return siblingWordLexeme
        //                                                             }
        //                                                         )
        //                                                     }

        //                                                     return siblingWord
        //                                                 }
        //                                             )

        //                                             return siblingWordGroup
        //                                         }
        //                                     )
        //                                 }
        //                             }

        //                             return lexeme
        //                         })
        //                     }

        //                     return word
        //                 })

        //                 return wordGroup
        //             })
        //         }
        //     }

        //     for (const parentKey in data) {
        //         let numLexemes = 0
        //         let sharedLexemes = 0

        //         for (const childKey in data[parentKey]) {
        //             data[parentKey][childKey].map((wordGroup) => {
        //                 wordGroup.map((word) => {
        //                     if (word.lexeme) {
        //                         numLexemes += 1
        //                     }
        //                     if (word.sharedLexeme) {
        //                         sharedLexemes += 1
        //                     }
        //                 })
        //             })
        //         }
        //     }
        // }

        // addSharedLexemeProperty(newStore)
        setWlaStore(newStore)
    }, [encodingsStore, mainTextInfo?.textEncoding])

    const loadTextInfo = useCallback(
        async (textId: string, bookId: string, chapter: number) => {
            const textsResponse = await supabase
                .from('verses')
                .select(
                    `
        *,
        verse!inner(*, book_name!inner(name, seo)),
        text!inner(*)
`
                )
                .match({
                    'text.seo': textId,
                    'verse.book_name.seo': bookId,
                })
                .limit(1)
                .single()

            if (textsResponse.data) {
                setMainTextInfo({
                    textEncoding: textsResponse.data.text.encoding,
                    textId: textsResponse.data.text.id,
                    textTitle: textsResponse.data.text.title,
                    textSeo: textsResponse.data.text.seo,
                    bookName: textsResponse.data.verse.book_name.name,
                    bookSeo: textsResponse.data.verse.book_name.seo,
                    chapter,
                })
            }
        },
        []
    )

    const loadPageData = useCallback(
        async (mainTextInfo: ITextInfo) => {
            const textIds = [
                mainTextInfo.textId,
                ...selectedOtherTexts
                    .filter(
                        (text) =>
                            text.encoding === 'syriacetcbc' ||
                            text.encoding === 'geezetcbc' ||
                            text.encoding === 'aramaiccal'
                    )
                    .map((text) => text.code),
            ]

            const optionsResponse = await supabase
                .from('texts')
                .select(
                    `
                    *,
                    verses!inner(*, verse!inner(chapter, book_name))
            `
                )
                .match({
                    'verses.verse.chapter': mainTextInfo.chapter,
                    'verses.verse.book_name': mainTextInfo.bookName,
                })
                .neq('id', mainTextInfo.textId)

            if (optionsResponse.data && optionsResponse.data.length) {
                const parsedOptions = [
                    {
                        code: 'Bible',
                        name: 'Bible',
                        items: optionsResponse.data
                            .filter((d: { type: string }) => d.type === 'bible')
                            .map(
                                (option: {
                                    id: string
                                    title: string
                                    encoding: string
                                }) => ({
                                    code: option.id,
                                    name: option.title,
                                    encoding: option.encoding,
                                })
                            ),
                    },
                    {
                        code: 'Commentary',
                        name: 'Commentary',
                        items: optionsResponse.data
                            .filter(
                                (d: { type: string }) => d.type === 'commentary'
                            )
                            .map(
                                (option: {
                                    id: string
                                    title: string
                                    encoding: string
                                }) => ({
                                    code: option.id,
                                    name: option.title,
                                    encoding: option.encoding,
                                })
                            ),
                    },
                ]

                setOptions(parsedOptions)
            }

            const toggledIds = [
                mainTextInfo.textId,
                ...selectedOtherTexts.map((text) => text.code),
            ]
            const versesResponse = await supabase
                .from('bible_verses')
                .select(
                    `
                *,
                verses!inner(*, text!inner(id, encoding, title, type, seo))        
                `
                )
                .match({
                    chapter: mainTextInfo.chapter,
                    book_name: mainTextInfo.bookName,
                })
                .order('number', { ascending: true })
                .in('verses.text.id', toggledIds)

            const d = await supabase
                .from('bible_verses')
                .select(
                    `
                *,
                verses!inner(*, text!inner(*))        
                `
                )
                .match({
                    chapter: mainTextInfo.chapter,
                    book_name: mainTextInfo.bookName,
                })
                .order('number', { ascending: true })
                .in('verses.text.id', toggledIds)

            const encodings = await supabase
                .from('etcbc_encoding')
                .select(
                    `
                *,
                verse!inner(*,  text!inner(encoding, seo), verse!inner(*, book_name!inner(seo))),
                etcbc_encoding_pendingapproval(*, user_id(*))
                `
                )
                .match({
                    'verse.verse.chapter': mainTextInfo.chapter,
                    'verse.verse.book_name.seo': mainTextInfo.bookSeo,
                })
                .in('verse.text.id', textIds)

            const parsedEncodings = {} as IEncodingStore

            encodings.data
                ?.sort((a, b) => a.verse.verse.number - b.verse.verse.number)
                .map(
                    (encoding: {
                        etcbc_encoding_pendingapproval: {
                            body: string
                        }
                        verse: {
                            id: string
                            verse: { chapter: number; number: number }
                            text: { seo: string; encoding: string }
                        }
                        body: string
                    }) => {
                        if (encoding.verse.text.encoding === 'aramaiccal') {
                            return encoding
                        }

                        if (
                            !(
                                `${encoding.verse.verse.chapter}-${encoding.verse.verse.number}` in
                                parsedEncodings
                            )
                        )
                            parsedEncodings[
                                `${encoding.verse.verse.chapter}-${encoding.verse.verse.number}`
                            ] = {}

                        parsedEncodings[
                            `${encoding.verse.verse.chapter}-${encoding.verse.verse.number}`
                        ][encoding.verse.id] =
                            encoding.etcbc_encoding_pendingapproval
                                ? encoding.etcbc_encoding_pendingapproval.body
                                : encoding.body

                        return encoding
                    }
                )

            setEncodingsStore(parsedEncodings)

            const versesOrder = ['bible', 'commentary']

            if (versesResponse.data?.length) {
                const reshuffledVerses = versesResponse.data.map(
                    (parentVerse) => {
                        const rawEncoding = encodings.data?.find(
                            (e) => e.verse.verse.id === parentVerse.id
                        )

                        let encoding = null

                        if (rawEncoding) {
                            encoding = {
                                body: rawEncoding.body,
                                id: rawEncoding.id,
                                suggestedChange:
                                    rawEncoding.etcbc_encoding_pendingapproval,
                            }
                        }

                        return {
                            ...parentVerse,
                            encoding,
                            verses: parentVerse.verses
                                .sort(
                                    (
                                        a: { text: { id: string } },
                                        b: { text: { id: string } }
                                    ) =>
                                        toggledIds.indexOf(a.text.id) -
                                        toggledIds.indexOf(b.text.id)
                                )
                                .sort(
                                    (
                                        a: { text: { type: string } },
                                        b: { text: { type: string } }
                                    ) =>
                                        versesOrder.indexOf(a.text.type) -
                                        versesOrder.indexOf(b.text.type)
                                ),
                        }
                    }
                )

                setVerses(reshuffledVerses)
            }
        },
        [selectedOtherTexts]
    )

    useEffect(() => {
        if (mainTextInfo) {
            loadPageData(mainTextInfo)
        }
    }, [loadPageData, mainTextInfo])

    useEffect(() => {
        if (mainTextInfo) {
            loadWlaData()
        }
    }, [loadWlaData, selectedOtherTexts, mainTextInfo])

    useEffect(() => {
        if (textId && bookId && chapterId) {
            loadTextInfo(textId, bookId, parseInt(chapterId))
        }
    }, [loadTextInfo, textId, bookId, chapterId])

    return (
        <ReaderContext.Provider value={{ state, dispatch }}>
            <HStack alignItems="flex-start">
                <VStack
                    spacing="3"
                    background="white"
                    padding={{
                        base: '16px 8px',
                        md: '24px',
                    }}
                    borderRadius={{
                        base: '8px',
                        md: '24px',
                    }}
                >
                    <Breadcrumb spacing="8px" separator={<ChevronRight />}>
                        <BreadcrumbItem>
                            <BreadcrumbLink as={Link} to="/texts">
                                Texts
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <BreadcrumbLink
                                as={Link}
                                to={`/texts/${mainTextInfo?.textSeo}`}
                            >
                                {mainTextInfo?.textTitle}
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <BreadcrumbLink
                                as={Link}
                                to={`/texts/${mainTextInfo?.textSeo}/${mainTextInfo?.bookSeo}`}
                            >
                                {mainTextInfo?.bookName}
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <Tag _hover={{ cursor: 'default' }}>
                                {mainTextInfo?.chapter}
                            </Tag>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Heading textAlign="center" color={'twitter.800'}>
                        {mainTextInfo?.bookName}: {chapterId}
                    </Heading>
                    <VStack
                        width="100%"
                        position="sticky"
                        top="0"
                        zIndex="2"
                        background={'white'}
                        padding="16px 0"
                        spacing={'5'}
                    >
                        <HStack
                            width="100%"
                            spacing="5"
                            justifyContent="flex-end"
                            flexWrap="wrap"
                            rowGap={'16px'}
                            padding="16px 0"
                        >
                            <HStack>
                                {mainTextInfo?.textEncoding ===
                                    'syriacetcbc' && (
                                    <FormControl
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <FormLabel
                                            htmlFor="westernscript"
                                            mb="0"
                                        >
                                            Estrangelo
                                        </FormLabel>
                                        <Switch
                                            size="md"
                                            id="westernscript"
                                            onChange={() =>
                                                setEnableWesternScript(
                                                    !enableWesternScript
                                                )
                                            }
                                        />
                                    </FormControl>
                                )}
                                {(mainTextInfo?.textEncoding ===
                                    'syriacetcbc' ||
                                    mainTextInfo?.textEncoding ===
                                        'geezetcbc') && (
                                    <FormControl
                                        display={{
                                            base: 'none',
                                            md: 'flex',
                                        }}
                                        alignItems="center"
                                    >
                                        <FormLabel htmlFor="encoding" mb="0">
                                            Encoding
                                        </FormLabel>
                                        <Switch
                                            size="md"
                                            id="encoding"
                                            defaultChecked={displayEncoding}
                                            onChange={() =>
                                                setDisplayEncoding(
                                                    !displayEncoding
                                                )
                                            }
                                        />
                                    </FormControl>
                                )}
                            </HStack>
                            <Cascader
                                size="middle"
                                fieldNames={{
                                    label: 'name',
                                    value: 'code',
                                    children: 'items',
                                }}
                                options={options}
                                onChange={(value) => {
                                    const filteredIds = value
                                        .filter(
                                            (pair) =>
                                                (pair[0] as string).indexOf(
                                                    'Bible'
                                                ) > -1 ||
                                                (pair[0] as string).indexOf(
                                                    'Commentary'
                                                ) > -1
                                        )
                                        .map((pair) => pair[1] as string)

                                    const result: Option[] = []

                                    options.forEach((category) => {
                                        category?.items?.forEach((item) => {
                                            if (
                                                filteredIds.includes(item.code)
                                            ) {
                                                result.push(item)
                                            }
                                        })
                                    })

                                    setSelectedOtherTexts(result)
                                }}
                                placeholder="More options"
                                multiple
                                maxTagCount="responsive"
                                showCheckedStrategy={Cascader.SHOW_CHILD}
                            />
                        </HStack>
                        {/* {displayEncoding && (
                            <>
                                <Divider />
                                <HStack w="100%">
                                    <FormControl
                                        as="fieldset"
                                        border={
                                            '1px solid  var(--chakra-colors-gray-200)'
                                        }
                                        borderRadius="0.25rem"
                                        p="5"
                                    >
                                        <FormControl
                                            display={{
                                                base: 'none',
                                                md: 'flex',
                                            }}
                                            alignItems="center"
                                        >
                                            <FormLabel
                                                htmlFor="show-errors"
                                                mb="0"
                                            >
                                                Show all errors
                                            </FormLabel>
                                            <Switch
                                                size="sm"
                                                id="show-errors"
                                                defaultChecked={
                                                    showErrorsByDefault
                                                }
                                                onChange={() =>
                                                    setShowErrorsByDefault(
                                                        !showErrorsByDefault
                                                    )
                                                }
                                            />
                                        </FormControl>
                                    </FormControl>
                                </HStack>
                            </>
                        )} */}
                        {/* {selectedOtherTexts.length && (
                            <>
                                <Divider />
                                <HStack w="100%">
                                    <FormControl
                                        as="fieldset"
                                        border={
                                            '1px solid  var(--chakra-colors-gray-200)'
                                        }
                                        borderRadius="0.25rem"
                                        p="5"
                                    >
                                        <FormLabel as="legend">
                                            Lexeme comparison
                                        </FormLabel>
                                        <RadioGroup
                                            defaultValue={lexemeComparator}
                                            onChange={(nextValue: string) =>
                                                setLexemeComparator(
                                                    nextValue as ILexemeComparatorOption
                                                )
                                            }
                                            marginTop="-1rem"
                                        >
                                            <HStack spacing="24px">
                                                <Radio value="off">Off</Radio>
                                                <Radio value="conv">
                                                    Convergence
                                                </Radio>
                                                <Radio value="div">
                                                    Divergence
                                                </Radio>
                                                <Radio value="both">Both</Radio>
                                            </HStack>
                                        </RadioGroup>
                                    </FormControl>
                                </HStack>
                            </>
                        )} */}
                    </VStack>
                    {verses.map((parentVerse) => {
                        return (
                            <Verse
                                key={parentVerse.id}
                                verse={parentVerse}
                                enableWesternScript={enableWesternScript}
                                showErrorsByDefault={showErrorsByDefault}
                                lexemeComparator={lexemeComparator}
                                displayEncoding={displayEncoding}
                                mainTextInfo={mainTextInfo || ({} as ITextInfo)}
                                chapterId={chapterId}
                                wlaStore={wlaStore}
                                qoroyoIdentifier={`${parentVerse.chapter}-${parentVerse.number}`}
                            />
                        )
                    })}
                </VStack>
                <Hide below="md">
                    <VStack minW={'14.93rem'} position="sticky" top="0">
                        <EtcbcLexicon />
                        <Lookup
                            word={state.lookupWord.word}
                            timestamp={state.lookupWord.timestamp}
                        />
                    </VStack>
                </Hide>
            </HStack>
        </ReaderContext.Provider>
    )
}

export default Reader
