import {
    Button,
    HStack,
    Tag,
    useToast,
    VStack,
    Text,
    Badge,
} from '@chakra-ui/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { HiCheck } from 'react-icons/hi'
import { convertEncodingToUnicode } from '../Decoder'
import { supabase } from '../../supabaseClient'
import ReactTextareaAutosize from 'react-textarea-autosize'
import { useAuth } from '../../contexts/Auth'
import { useRef } from 'react'
import { Roles } from '../../types/Roles'
import { Formik } from 'formik'
import * as diff from 'diff'
import axios from 'axios'
import {
    EtcbcEncoding,
    EncodingContainer,
    OptionLabel,
    CommentaryText,
    TextAreaContainer,
    constructRequestParams,
} from '../../pages/Reader'
import { SurfaceTextDiff } from './SurfaceTextDiff'
import { useProfile } from '../../contexts/Profile'
import { IWordLevelAnalysis } from './WordLevelAnalysis'

export const Encoder: React.FC<{
    encoding: EtcbcEncoding
    westernScript: boolean
    suggestedChange: {
        id: string
        body: string
        user_id: {
            id: string
            first_name: string
            last_name: string
        }
    } | null
    textEncoding: string
}> = ({ encoding, westernScript, suggestedChange, textEncoding }) => {
    const toast = useToast()
    const textAreaRef = useRef<HTMLTextAreaElement>(null)
    const [pendingChange, setPendingChange] = useState(suggestedChange)

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isCancelling, setIsCancelling] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isApproving, setIsApproving] = useState(false)

    const [newProposedValue, setNewProposedValue] = useState(encoding.body)
    const [displayChoice, setDisplayChoice] = useState({
        defaultSubmit: false,
        defaultCancel: false,
        update: false,
        cancelSubmission: false,
    })

    const [serverValidationErrors, setServerValidationErrors] = useState<
        { code: string; error: string }[]
    >([] as { code: string; error: string }[])

    const session = useAuth()
    const { role } = useProfile()

    const [wlaData, setWlaData] = useState<Array<Array<IWordLevelAnalysis>>>(
        [] as IWordLevelAnalysis[][]
    )

    useEffect(() => {
        if (pendingChange) {
            setNewProposedValue(pendingChange.body)

            setDisplayChoice({
                defaultSubmit: false,
                defaultCancel: false,
                update: false,
                cancelSubmission: true,
            })
        }
    }, [encoding, pendingChange])

    const changes = useMemo(() => {
        return diff.diffChars(encoding.body, newProposedValue)
    }, [encoding.body, newProposedValue])

    useEffect(() => {
        const channel = supabase.channel(encoding.id)

        channel
            .on(
                'postgres_changes',
                {
                    event: 'INSERT',
                    schema: 'public',
                    table: 'etcbc_encoding_pendingapproval',
                },
                (data) => {
                    if (data.new.encoding === encoding.id) {
                        setDisplayChoice({
                            defaultSubmit: false,
                            defaultCancel: false,
                            update: false,
                            cancelSubmission: true,
                        })

                        setPendingChange(
                            data.new as {
                                id: string
                                body: string
                                user_id: {
                                    id: string
                                    first_name: string
                                    last_name: string
                                }
                            }
                        )

                        setNewProposedValue(data.new.body)
                    }
                }
            )
            .on(
                'postgres_changes',
                {
                    event: 'DELETE',
                    schema: 'public',
                    table: 'etcbc_encoding_pendingapproval',
                },
                (data) => {
                    if (data.old.id === pendingChange?.id) {
                        setPendingChange(null)
                        setNewProposedValue(encoding.body)
                        setDisplayChoice({
                            defaultSubmit: false,
                            defaultCancel: false,
                            update: false,
                            cancelSubmission: false,
                        })
                    }
                }
            )
            .subscribe()

        return () => {
            supabase.removeChannel(channel)
        }
    }, [encoding.body, encoding.id, pendingChange?.id, suggestedChange?.id])

    if (
        role !== Roles.Admin &&
        pendingChange &&
        session?.user?.id !== pendingChange?.user_id.id
    ) {
        return (
            <EncodingContainer style={{ width: '100%', position: 'relative' }}>
                <OptionLabel>Encoding</OptionLabel>
                <VStack spacing="5">
                    <CommentaryText style={{ marginTop: '1rem' }}>
                        Already encoded. Waiting approval.
                    </CommentaryText>
                </VStack>
            </EncodingContainer>
        )
    }

    const COLOR_ADDITION = '#8fbc8f'
    const COLOR_REMOVAL = '#ffd700'

    return (
        <EncodingContainer
            style={{
                width: '100%',
                position: 'relative',
                minWidth: '100%',
                margin: '2rem 0 1rem',
            }}
        >
            <OptionLabel>Encoding</OptionLabel>
            <VStack spacing="5">
                <Formik
                    initialValues={{
                        proposedvalue: pendingChange?.body
                            ? pendingChange?.body
                            : newProposedValue,
                    }}
                    validate={async (values) => {
                        const data = constructRequestParams(textEncoding, [
                            values.proposedvalue,
                        ])

                        const result = await axios.post(
                            `https://etcbc.vu.nl/api/wla`,
                            data
                        )

                        const realisedWords: string[] = []

                        ;(
                            result.data?.result[0] as Array<
                                Array<IWordLevelAnalysis>
                            >
                        ).map((wordgroup) => {
                            let concatWord = ''

                            wordgroup.map((word) => {
                                concatWord += word.realisation
                            })

                            realisedWords.push(concatWord)
                        })

                        const errors = { proposedvalue: '' }
                        if (!values.proposedvalue) {
                            errors.proposedvalue =
                                'Proposed value cannot be empty'
                        } else if (
                            convertEncodingToUnicode(
                                realisedWords.join(' ')
                            ) !== convertEncodingToUnicode(values.proposedvalue)
                        ) {
                            errors.proposedvalue =
                                'Proposed encoding does not match surface text'
                        }

                        return errors
                    }}
                    onSubmit={() => {
                        return
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched,
                    }) => (
                        <>
                            {changes.length > 1 && (
                                <Badge
                                    mt="5"
                                    alignSelf="flex-start"
                                    variant="outline"
                                    padding={'0.65rem'}
                                    borderRadius={'5px'}
                                    boxShadow="none"
                                    backgroundColor="#fff"
                                    color="#008b8b"
                                >
                                    Suggested changes by{' '}
                                    <Badge ml="5" padding="0.3rem 0.5rem">
                                        {
                                            encoding.suggestedChange?.user_id
                                                .first_name
                                        }{' '}
                                        {
                                            encoding.suggestedChange?.user_id
                                                .last_name
                                        }
                                    </Badge>
                                </Badge>
                            )}
                            <TextAreaContainer style={{}}>
                                <form
                                    onSubmit={handleSubmit}
                                    style={{ width: '100%' }}
                                >
                                    <ReactTextareaAutosize
                                        name="proposedvalue"
                                        ref={textAreaRef}
                                        minRows={4}
                                        value={values.proposedvalue}
                                        onKeyDown={() =>
                                            setFieldTouched('proposedvalue')
                                        }
                                        onChange={async (e) => {
                                            handleChange(e)
                                            setNewProposedValue(e.target.value)

                                            if (!pendingChange) {
                                                setDisplayChoice({
                                                    defaultSubmit:
                                                        e.target.value !==
                                                        encoding.body,
                                                    defaultCancel:
                                                        e.target.value !==
                                                        encoding.body,
                                                    update: false,
                                                    cancelSubmission: false,
                                                })
                                            }

                                            if (pendingChange) {
                                                setDisplayChoice({
                                                    defaultSubmit: false,
                                                    defaultCancel: false,
                                                    update:
                                                        e.target.value !==
                                                        pendingChange?.body,
                                                    cancelSubmission: true,
                                                })
                                            }

                                            const data = constructRequestParams(
                                                textEncoding,
                                                [e.target.value]
                                            )

                                            const result = await axios.post(
                                                `https://etcbc.vu.nl/api/wla`,
                                                data
                                            )

                                            const errors: {
                                                code: string
                                                error: string
                                            }[] = []

                                            result.data.result[0]?.map(
                                                (
                                                    r: {
                                                        code: string
                                                        error?: string
                                                    }[]
                                                ) => {
                                                    r.map((token) => {
                                                        if (token.error) {
                                                            errors.push(
                                                                token as {
                                                                    code: string
                                                                    error: string
                                                                }
                                                            )
                                                        }

                                                        return token
                                                    })

                                                    return r
                                                }
                                            )

                                            setServerValidationErrors(errors)
                                        }}
                                        onBlur={handleBlur}
                                    />
                                </form>
                            </TextAreaContainer>
                            {changes.length > 1 && (
                                <>
                                    <Badge
                                        alignSelf="flex-start"
                                        variant="outline"
                                        padding={'0.65rem'}
                                        borderRadius={'5px'}
                                        boxShadow="none"
                                        backgroundColor="#fff"
                                        color="#008b8b"
                                    >
                                        Before changes:
                                    </Badge>
                                    <Text
                                        style={{
                                            fontFamily: "'Barlow', monospace",
                                            wordSpacing: '5px',
                                            lineHeight: 2,
                                        }}
                                    >
                                        {encoding.body}
                                    </Text>{' '}
                                    <Badge
                                        padding={'0.65rem'}
                                        variant="outline"
                                        alignSelf="flex-start"
                                        borderRadius={'5px'}
                                        boxShadow="none"
                                        backgroundColor="#fff"
                                        color="#008b8b"
                                    >
                                        Overview changes:{' '}
                                        <Badge
                                            mr="2"
                                            ml="2"
                                            background={COLOR_ADDITION}
                                            padding="0.2rem 0.75rem"
                                        >
                                            Added
                                        </Badge>
                                        <Badge
                                            background={COLOR_REMOVAL}
                                            padding="0.2rem 0.75rem"
                                        >
                                            Removed
                                        </Badge>
                                    </Badge>
                                    <HStack
                                        spacing={0}
                                        style={{
                                            fontFamily: "'Barlow', monospace",
                                            wordSpacing: '5px',
                                            lineHeight: 2,
                                        }}
                                        display="inline-block"
                                    >
                                        {changes.map((change) => {
                                            if (change.removed) {
                                                return (
                                                    <Text
                                                        as="span"
                                                        style={{
                                                            background:
                                                                COLOR_REMOVAL,
                                                            borderRadius: '3px',
                                                        }}
                                                    >
                                                        {change.value}
                                                    </Text>
                                                )
                                            }
                                            if (change.added) {
                                                return (
                                                    <Text
                                                        as="span"
                                                        style={{
                                                            background:
                                                                COLOR_ADDITION,
                                                            borderRadius: '3px',
                                                        }}
                                                    >
                                                        {change.value}
                                                    </Text>
                                                )
                                            }
                                            return (
                                                <Text as="span">
                                                    {change.value}
                                                </Text>
                                            )
                                        })}
                                    </HStack>
                                </>
                            )}

                            {errors.proposedvalue &&
                                touched.proposedvalue &&
                                errors.proposedvalue &&
                                textEncoding === 'syriacetcbc' && (
                                    <>
                                        <Tag>Preview:</Tag>
                                        <SurfaceTextDiff
                                            westernScript={westernScript}
                                            surfaceText={encoding.body}
                                            newProposedValue={newProposedValue}
                                        />
                                        <Text
                                            color="red"
                                            align="center"
                                            fontSize="small"
                                            mt="2"
                                        >
                                            {errors.proposedvalue &&
                                                touched.proposedvalue &&
                                                errors.proposedvalue}
                                        </Text>
                                    </>
                                )}
                            {serverValidationErrors.map((error) => (
                                <Text
                                    color="red"
                                    align="center"
                                    fontSize="small"
                                    mt="2"
                                    backgroundColor={'#ffffff'}
                                    border={'1px solid #c6d1db'}
                                    p="0.5rem 1.5rem 0.5rem 1rem"
                                    borderRadius={'2rem'}
                                >
                                    <HStack>
                                        <span
                                            style={{
                                                backgroundColor: '#faebd7',
                                                borderRadius: '1rem',
                                                padding: '0.5rem 1rem',
                                            }}
                                        >
                                            {error.code}
                                        </span>
                                        <span>
                                            {error.error.replace(
                                                'analyse: ',
                                                ''
                                            )}
                                        </span>
                                    </HStack>
                                </Text>
                            ))}
                            <HStack justifyContent={'flex-end'} spacing="2">
                                {!errors.proposedvalue &&
                                    displayChoice.update &&
                                    session?.user?.id ===
                                        pendingChange?.user_id && (
                                        <Button
                                            leftIcon={<HiCheck />}
                                            width="fit-content"
                                            colorScheme="teal"
                                            size="sm"
                                            isLoading={isUpdating}
                                            loadingText="Updating"
                                            onClick={async () => {
                                                const toastId = 'update-toast'

                                                setIsUpdating(true)

                                                try {
                                                    const { error } =
                                                        await supabase
                                                            .from(
                                                                'etcbc_encoding_pendingapproval'
                                                            )
                                                            .update({
                                                                body: newProposedValue,
                                                            })
                                                            .eq(
                                                                'id',
                                                                pendingChange?.id
                                                            )
                                                            .select()

                                                    if (error) {
                                                        throw error
                                                    }
                                                    setDisplayChoice({
                                                        defaultSubmit: false,
                                                        defaultCancel: false,
                                                        update: false,
                                                        cancelSubmission: true,
                                                    })
                                                    setIsUpdating(false)
                                                    toast({
                                                        id: toastId,
                                                        title: 'Info',
                                                        description:
                                                            'Update completed',
                                                        status: 'success',
                                                        duration: 3000,
                                                        isClosable: true,
                                                        variant: 'subtle',
                                                        position: 'top',
                                                    })
                                                } catch (error) {
                                                    setIsUpdating(false)
                                                    toast({
                                                        id: toastId,
                                                        title: 'Issue',
                                                        description:
                                                            'Update failed',
                                                        status: 'error',
                                                        duration: 3000,
                                                        isClosable: true,
                                                        variant: 'subtle',
                                                        position: 'bottom',
                                                    })
                                                }
                                            }}
                                        >
                                            Update
                                        </Button>
                                    )}
                                {role === Roles.Admin &&
                                    displayChoice.cancelSubmission && (
                                        <Button
                                            isLoading={isApproving}
                                            loadingText="Approving"
                                            width="fit-content"
                                            colorScheme="green"
                                            size="sm"
                                            isDisabled={isUpdating}
                                            onClick={async () => {
                                                const toastId =
                                                    'toast-approve-btn'
                                                setIsApproving(true)

                                                const { error: encodingError } =
                                                    await supabase
                                                        .from('etcbc_encoding')
                                                        .update({
                                                            body: newProposedValue,
                                                        })
                                                        .eq('id', encoding.id)
                                                const { error: approvalError } =
                                                    await supabase
                                                        .from(
                                                            'etcbc_encoding_pendingapproval'
                                                        )
                                                        .delete()
                                                        .eq(
                                                            'encoding',
                                                            encoding.id
                                                        )
                                                const { error: historyError } =
                                                    await supabase
                                                        .from(
                                                            'etcbc_encoding_history'
                                                        )
                                                        .insert([
                                                            {
                                                                action: 'encoded',
                                                                encoding:
                                                                    encoding.id,
                                                                user_id:
                                                                    encoding
                                                                        .suggestedChange
                                                                        ?.user_id
                                                                        .id,
                                                                changes_log:
                                                                    encoding
                                                                        .suggestedChange
                                                                        ?.body,
                                                            },
                                                            {
                                                                action: 'approve',
                                                                encoding:
                                                                    encoding.id,
                                                                user_id:
                                                                    session
                                                                        ?.user
                                                                        ?.id,
                                                                changes_log:
                                                                    newProposedValue,
                                                            },
                                                        ])

                                                if (
                                                    encodingError ||
                                                    approvalError ||
                                                    historyError
                                                ) {
                                                    if (
                                                        !toast.isActive(toastId)
                                                    ) {
                                                        toast({
                                                            id: toastId,
                                                            title: 'Issue',
                                                            description:
                                                                'Could not approve encoding',
                                                            status: 'error',
                                                            duration: 3000,
                                                            isClosable: true,
                                                            variant: 'subtle',
                                                            position: 'bottom',
                                                        })
                                                    }
                                                    setIsApproving(false)
                                                } else {
                                                    setDisplayChoice({
                                                        defaultSubmit: false,
                                                        defaultCancel: false,
                                                        update: false,
                                                        cancelSubmission: false,
                                                    })

                                                    if (
                                                        textAreaRef.current
                                                            ?.value
                                                    ) {
                                                        setNewProposedValue(
                                                            textAreaRef.current
                                                                ?.value
                                                        )
                                                    }

                                                    setPendingChange(null)

                                                    toast({
                                                        id: toastId,
                                                        title: 'Info',
                                                        description:
                                                            'Submission approved',
                                                        status: 'success',
                                                        duration: 3000,
                                                        isClosable: true,
                                                        variant: 'subtle',
                                                        position: 'top',
                                                    })

                                                    setIsApproving(false)
                                                }
                                            }}
                                        >
                                            Approve
                                        </Button>
                                    )}
                                {displayChoice.cancelSubmission && (
                                    <Button
                                        isLoading={isCancelling}
                                        loadingText="Cancelling"
                                        width="fit-content"
                                        colorScheme="orange"
                                        size="sm"
                                        isDisabled={isUpdating}
                                        onClick={async () => {
                                            const toastId =
                                                'error-cancellation-toast'

                                            setIsCancelling(true)

                                            try {
                                                const { error } = await supabase
                                                    .from(
                                                        'etcbc_encoding_pendingapproval'
                                                    )
                                                    .delete()
                                                    .eq('id', pendingChange?.id)

                                                if (error) {
                                                    throw error
                                                }

                                                setPendingChange(null)
                                                setIsCancelling(false)
                                                setNewProposedValue(
                                                    encoding.body
                                                )
                                                setFieldValue(
                                                    'proposedvalue',
                                                    encoding.body
                                                )
                                                setDisplayChoice({
                                                    defaultSubmit: false,
                                                    defaultCancel: false,
                                                    update: false,
                                                    cancelSubmission: false,
                                                })
                                                toast({
                                                    id: toastId,
                                                    title: 'Info',
                                                    description:
                                                        'Submission cancelled',
                                                    status: 'success',
                                                    duration: 3000,
                                                    isClosable: true,
                                                    variant: 'subtle',
                                                    position: 'top',
                                                })
                                            } catch (error) {
                                                if (!toast.isActive(toastId)) {
                                                    toast({
                                                        id: toastId,
                                                        title: 'Issue',
                                                        description:
                                                            'Could not cancel submission',
                                                        status: 'error',
                                                        duration: 3000,
                                                        isClosable: true,
                                                        variant: 'subtle',
                                                        position: 'bottom',
                                                    })
                                                }
                                                setIsCancelling(false)
                                            }
                                        }}
                                    >
                                        {role === Roles.Admin &&
                                        session?.user?.id !==
                                            pendingChange?.user_id
                                            ? 'Reject'
                                            : 'Cancel submission'}
                                    </Button>
                                )}
                                {!errors.proposedvalue &&
                                    !serverValidationErrors.length &&
                                    displayChoice.defaultSubmit && (
                                        <Button
                                            leftIcon={<HiCheck />}
                                            width="fit-content"
                                            isLoading={isSubmitting}
                                            loadingText="Submitting"
                                            colorScheme="teal"
                                            size="sm"
                                            onClick={async () => {
                                                const toastId =
                                                    'default-submit-toast'

                                                setIsSubmitting(true)

                                                try {
                                                    const { data, error } =
                                                        await supabase
                                                            .from(
                                                                'etcbc_encoding_pendingapproval'
                                                            )
                                                            .insert({
                                                                encoding:
                                                                    encoding.id,
                                                                user_id:
                                                                    session
                                                                        ?.user
                                                                        ?.id,
                                                                body: newProposedValue,
                                                            })
                                                            .select()

                                                    if (error) {
                                                        throw error
                                                    }

                                                    if (data) {
                                                        setPendingChange(
                                                            data[0]
                                                        )
                                                    }

                                                    setDisplayChoice({
                                                        defaultSubmit: false,
                                                        defaultCancel: false,
                                                        update: false,
                                                        cancelSubmission: true,
                                                    })

                                                    toast({
                                                        id: toastId,
                                                        title: 'Info',
                                                        description:
                                                            'Submitted',
                                                        status: 'success',
                                                        duration: 3000,
                                                        isClosable: true,
                                                        variant: 'subtle',
                                                        position: 'top',
                                                    })

                                                    setIsSubmitting(false)
                                                } catch (error) {
                                                    if (
                                                        !toast.isActive(toastId)
                                                    ) {
                                                        toast({
                                                            id: toastId,
                                                            title: 'Issue',
                                                            description:
                                                                'Could not submit encoding',
                                                            status: 'error',
                                                            duration: 3000,
                                                            isClosable: true,
                                                            variant: 'subtle',
                                                            position: 'bottom',
                                                        })
                                                    }
                                                    setIsSubmitting(false)
                                                }
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    )}
                                {displayChoice.defaultCancel && (
                                    <Button
                                        width="fit-content"
                                        variant={
                                            !errors.proposedvalue
                                                ? 'link'
                                                : 'solid'
                                        }
                                        colorScheme={'teal'}
                                        size={
                                            !errors.proposedvalue ? 'xs' : 'sm'
                                        }
                                        onClick={() => {
                                            setNewProposedValue(encoding.body)

                                            setFieldValue(
                                                'proposedvalue',
                                                encoding.body
                                            )

                                            setDisplayChoice({
                                                defaultSubmit: false,
                                                defaultCancel: false,
                                                update: false,
                                                cancelSubmission: false,
                                            })
                                        }}
                                    >
                                        Undo
                                    </Button>
                                )}
                            </HStack>
                        </>
                    )}
                </Formik>
            </VStack>
        </EncodingContainer>
    )
}
