import { Input, Text, VStack } from '@chakra-ui/react'
import { useState } from 'react'

const syriacMapping = {
    '>': 'ܐ',
    B: 'ܒ',
    G: 'ܓ',
    D: 'ܕ',
    H: 'ܗ',
    W: 'ܘ',
    Z: 'ܙ',
    X: 'ܚ',
    V: 'ܛ',
    J: 'ܝ',
    K: 'ܟ',
    L: 'ܠ',
    M: 'ܡ',
    N: 'ܢ',
    S: 'ܣ',
    '<': 'ܥ',
    P: 'ܦ',
    Y: 'ܨ',
    Q: 'ܩ',
    R: 'ܪ',
    C: 'ܫ',
    T: 'ܬ',
    ' ': ' ',
}

const hebrewMapping = {
    '>': 'א',
    B: 'ב',
    G: 'ג',
    D: 'ד',
    H: 'ה',
    W: 'ו',
    Z: 'ז',
    X: 'ח',
    V: 'ט',
    J: 'י',
    K: 'כ',
    k: 'ך',
    L: 'ל',
    M: 'מ',
    m: 'ם',
    N: 'נ',
    n: 'ן',
    S: 'ס',
    '<': 'ע',
    P: 'פ',
    p: 'ף',
    Y: 'צ',
    y: 'ץ',
    Q: 'ק',
    R: 'ר',
    '#': 'ש',
    C: 'שׁ',
    F: 'שׂ',
    T: 'ת',
    ' ': ' ',
}

const aramaicMapping = {
    '>': 'א',
    B: 'ב',
    G: 'ג',
    D: 'ד',
    H: 'ה',
    W: 'ו',
    Z: 'ז',
    X: 'ח',
    V: 'ט',
    J: 'י',
    K: 'כ',
    k: 'ך',
    L: 'ל',
    M: 'מ',
    m: 'ם',
    N: 'נ',
    n: 'ן',
    S: 'ס',
    '<': 'ע',
    P: 'פ',
    p: 'ף',
    Y: 'צ',
    y: 'ץ',
    Q: 'ק',
    R: 'ר',
    C: 'ש',
    T: 'ת',
    ' ': ' ',
}

const geezMapping = {
    '1': '፩',
    h: 'ሀ',
    hu: 'ሁ',
    hi: 'ሂ',
    hA: 'ሃ',
    he: 'ሄ',
    "h'": 'ህ',
    ho: 'ሆ',
    hoa: 'ሇ',
    l: 'ለ',
    lu: 'ሉ',
    li: 'ሊ',
    lA: 'ላ',
    le: 'ሌ',
    "l'": 'ል',
    lo: 'ሎ',
    lva: 'ሏ',
    H: 'ሐ',
    Hu: 'ሑ',
    Hi: 'ሒ',
    HA: 'ሓ',
    He: 'ሔ',
    "H'": 'ሕ',
    Ho: 'ሖ',
    Hva: 'ሗ',
    m: 'መ',
    mu: 'ሙ',
    mi: 'ሚ',
    mA: 'ማ',
    me: 'ሜ',
    "m'": 'ም',
    mo: 'ሞ',
    mva: 'ሟ',
    c: 'ሠ',
    cu: 'ሡ',
    ci: 'ሢ',
    cA: 'ሣ',
    ce: 'ሤ',
    "c'": 'ሥ',
    co: 'ሦ',
    cva: 'ሧ',
    r: 'ረ',
    ru: 'ሩ',
    ri: 'ሪ',
    rA: 'ራ',
    re: 'ሬ',
    "r'": 'ር',
    ro: 'ሮ',
    rva: 'ሯ',
    s: 'ሰ',
    su: 'ሱ',
    si: 'ሲ',
    sA: 'ሳ',
    se: 'ሴ',
    "s'": 'ስ',
    so: 'ሶ',
    sva: 'ሷ',
    's*a': 'ሸ',
    's*u': 'ሹ',
    's*i': 'ሺ',
    's*A': 'ሻ',
    's*e': 'ሼ',
    "s*'": 'ሽ',
    's*o': 'ሾ',
    's*va': 'ሿ',
    q: 'ቀ',
    qu: 'ቁ',
    qi: 'ቂ',
    qA: 'ቃ',
    qe: 'ቄ',
    "q'": 'ቅ',
    qo: 'ቆ',
    qoa: 'ቇ',
    qva: 'ቈ',
    qvi: 'ቊ',
    qvA: 'ቋ',
    qve: 'ቌ',
    "qv'": 'ቍ',
    'q*a': 'ቐ',
    'q*u': 'ቑ',
    'q*i': 'ቒ',
    'q*A': 'ቓ',
    'q*e': 'ቔ',
    "q*'": 'ቕ',
    'q*o': 'ቖ',
    'q*va': 'ቘ',
    'q*vi': 'ቚ',
    'q*vA': 'ቛ',
    'q*ve': 'ቜ',
    "q*v'": 'ቝ',
    b: 'በ',
    bu: 'ቡ',
    bi: 'ቢ',
    bA: 'ባ',
    be: 'ቤ',
    "b'": 'ብ',
    bo: 'ቦ',
    bva: 'ቧ',
    'b*a': 'ቨ',
    'b*u': 'ቩ',
    'b*i': 'ቪ',
    'b*A': 'ቫ',
    'b*e': 'ቬ',
    "b*'": 'ቭ',
    'b*o': 'ቮ',
    'b*va': 'ቯ',
    t: 'ተ',
    tu: 'ቱ',
    ti: 'ቲ',
    tA: 'ታ',
    te: 'ቴ',
    "t'": 'ት',
    to: 'ቶ',
    tva: 'ቷ',
    'c*a': 'ቸ',
    'c*u': 'ቹ',
    'c*i': 'ቺ',
    'c*A': 'ቻ',
    'c*e': 'ቼ',
    "c*'": 'ች',
    'c*o': 'ቾ',
    'c*va': 'ቿ',
    x: 'ኀ',
    xu: 'ኁ',
    xi: 'ኂ',
    xA: 'ኃ',
    xe: 'ኄ',
    "x'": 'ኅ',
    xo: 'ኆ',
    xoa: 'ኇ',
    xva: 'ኈ',
    xvi: 'ኊ',
    xvA: 'ኋ',
    xve: 'ኌ',
    "xv'": 'ኍ',
    n: 'ነ',
    nu: 'ኑ',
    ni: 'ኒ',
    nA: 'ና',
    ne: 'ኔ',
    "n'": 'ን',
    no: 'ኖ',
    nva: 'ኗ',
    'n*a': 'ኘ',
    'n*u': 'ኙ',
    'n*i': 'ኚ',
    'n*A': 'ኛ',
    'n*e': 'ኜ',
    "n*'": 'ኝ',
    'n*o': 'ኞ',
    'n*va': 'ኟ',
    '>': 'አ',
    '>u': 'ኡ',
    '>i': 'ኢ',
    '>A': 'ኣ',
    '>e': 'ኤ',
    ">'": 'እ',
    '>o': 'ኦ',
    '>va': 'ኧ',
    k: 'ከ',
    ku: 'ኩ',
    ki: 'ኪ',
    kA: 'ካ',
    ke: 'ኬ',
    "k'": 'ክ',
    ko: 'ኮ',
    koa: 'ኯ',
    kva: 'ኰ',
    kvi: 'ኲ',
    kvA: 'ኳ',
    kve: 'ኴ',
    "kv'": 'ኵ',
    'x*a': 'ኸ',
    'x*u': 'ኹ',
    'x*i': 'ኺ',
    'x*A': 'ኻ',
    'x*e': 'ኼ',
    "x*'": 'ኽ',
    'x*o': 'ኾ',
    'x*va': 'ዀ',
    'x*vi': 'ዂ',
    'x*vA': 'ዃ',
    'x*ve': 'ዄ',
    "x*v'": 'ዅ',
    w: 'ወ',
    wu: 'ዉ',
    wi: 'ዊ',
    wA: 'ዋ',
    we: 'ዌ',
    "w'": 'ው',
    wo: 'ዎ',
    woa: 'ዏ',
    '<': 'ዐ',
    '<u': 'ዑ',
    '<i': 'ዒ',
    '<A': 'ዓ',
    '<e': 'ዔ',
    "<'": 'ዕ',
    '<o': 'ዖ',
    z: 'ዘ',
    zu: 'ዙ',
    zi: 'ዚ',
    zA: 'ዛ',
    ze: 'ዜ',
    "z'": 'ዝ',
    zo: 'ዞ',
    zva: 'ዟ',
    'z*a': 'ዠ',
    'z*u': 'ዡ',
    'z*i': 'ዢ',
    'z*A': 'ዣ',
    'z*e': 'ዤ',
    "z*'": 'ዥ',
    'z*o': 'ዦ',
    'z*va': 'ዧ',
    y: 'የ',
    yu: 'ዩ',
    yi: 'ዪ',
    yA: 'ያ',
    ye: 'ዬ',
    "y'": 'ይ',
    yo: 'ዮ',
    yoa: 'ዯ',
    d: 'ደ',
    du: 'ዱ',
    di: 'ዲ',
    dA: 'ዳ',
    de: 'ዴ',
    "d'": 'ድ',
    do: 'ዶ',
    dva: 'ዷ',
    'd*a': 'ዸ',
    'd*u': 'ዹ',
    'd*i': 'ዺ',
    'd*A': 'ዻ',
    'd*e': 'ዼ',
    "d*'": 'ዽ',
    'd*o': 'ዾ',
    'd*va': 'ዿ',
    'j*a': 'ጀ',
    'j*u': 'ጁ',
    'j*i': 'ጂ',
    'j*A': 'ጃ',
    'j*e': 'ጄ',
    "j*'": 'ጅ',
    'j*o': 'ጆ',
    'j*va': 'ጇ',
    g: 'ገ',
    gu: 'ጉ',
    gi: 'ጊ',
    gA: 'ጋ',
    ge: 'ጌ',
    "g'": 'ግ',
    go: 'ጎ',
    goa: 'ጏ',
    gva: 'ጐ',
    gvi: 'ጒ',
    gvA: 'ጓ',
    gve: 'ጔ',
    "gv'": 'ጕ',
    'G*a': 'ጘ',
    'G*u': 'ጙ',
    'G*i': 'ጚ',
    'G*A': 'ጛ',
    'G*e': 'ጜ',
    "G*'": 'ጝ',
    'G*o': 'ጞ',
    'G*vA': 'ጟ',
    T: 'ጠ',
    Tu: 'ጡ',
    Ti: 'ጢ',
    TA: 'ጣ',
    Te: 'ጤ',
    "T'": 'ጥ',
    To: 'ጦ',
    Tva: 'ጧ',
    'C*a': 'ጨ',
    'C*u': 'ጩ',
    'C*i': 'ጪ',
    'C*A': 'ጫ',
    'C*e': 'ጬ',
    "C*'": 'ጭ',
    'C*o': 'ጮ',
    'C*va': 'ጯ',
    P: 'ጰ',
    Pu: 'ጱ',
    Pi: 'ጲ',
    PA: 'ጳ',
    Pe: 'ጴ',
    "P'": 'ጵ',
    Po: 'ጶ',
    Pva: 'ጷ',
    S: 'ጸ',
    Su: 'ጹ',
    Si: 'ጺ',
    SA: 'ጻ',
    Se: 'ጼ',
    "S'": 'ጽ',
    So: 'ጾ',
    Sva: 'ጿ',
    C: 'ፀ',
    Cu: 'ፁ',
    Ci: 'ፂ',
    CA: 'ፃ',
    Ce: 'ፄ',
    "C'": 'ፅ',
    Co: 'ፆ',
    Coa: 'ፇ',
    f: 'ፈ',
    fu: 'ፉ',
    fi: 'ፊ',
    fA: 'ፋ',
    fe: 'ፌ',
    "f'": 'ፍ',
    fo: 'ፎ',
    fva: 'ፏ',
    p: 'ፐ',
    pu: 'ፑ',
    pi: 'ፒ',
    pA: 'ፓ',
    pe: 'ፔ',
    "p'": 'ፕ',
    po: 'ፖ',
    pva: 'ፗ',
    'r*a': 'ፘ',
    'm*a': 'ፙ',
    'f*a': 'ፚ',
}

type AramaicEncodingLetter = keyof typeof aramaicMapping
type SyriacEncodingLetter = keyof typeof syriacMapping
type GeezEncodingLetters = keyof typeof geezMapping
type HebrewEncodingLetter = keyof typeof hebrewMapping

export function convertGeezEncodingToUnicode(inputString: string) {
    const sortedKeys = Object.keys(geezMapping).sort(
        (a, b) => b.length - a.length
    ) // Sort by length, longest first

    let result = ''
    let i = 0

    if (inputString && inputString.length) {
        while (i < inputString.length) {
            let matched = false

            for (const key of sortedKeys) {
                if (inputString.startsWith(key, i)) {
                    result += geezMapping[key as GeezEncodingLetters]
                    i += key.length
                    matched = true
                    break
                }
            }

            if (!matched) {
                result += inputString[i] // Append the character as is if no match is found
                i++
            }
        }
    }
    return result
}

export const convertEncodingToUnicode = (input: string) => {
    let convertedString = ''

    for (const l of input.replace(/\((\w|[><])|:dp|:d|:p/gm, '')) {
        if (
            Object.prototype.hasOwnProperty.call(syriacMapping, l.toUpperCase())
        ) {
            convertedString =
                convertedString +
                syriacMapping[l.toUpperCase() as SyriacEncodingLetter]
        }
    }

    return convertedString
}

export const convertHebrewEncodingToUnicode = (input: string) => {
    let convertedString = ''

    if (input && input.length) {
        for (const l of input.replace(/\((\w|[><])|:dp|:d|:p/gm, '')) {
            if (
                Object.prototype.hasOwnProperty.call(
                    hebrewMapping,
                    l.toUpperCase()
                )
            ) {
                convertedString =
                    convertedString +
                    hebrewMapping[l.toUpperCase() as HebrewEncodingLetter]
            }
        }
    }

    return convertedString
}

export const convertCalToUnicode = (input: string) => {
    let convertedString = ''

    for (const l of input.replace(/\((\w|[><])|:dp|:d|:p/gm, '')) {
        if (Object.prototype.hasOwnProperty.call(aramaicMapping, l)) {
            convertedString =
                convertedString + aramaicMapping[l as AramaicEncodingLetter]
        }
    }

    return convertedString
}

const interpret = (ark: string) => {
    for (const token of ark.split('-')) {
        console.log(token)
    }
}

const Decoder = () => {
    const [unicodeConversion, setUnicodeConversion] = useState('')

    return (
        <VStack>
            <Input
                type="text"
                onChange={(e) => {
                    alert('change')
                    for (const ark of e.target.value.split(' ')) {
                        interpret(ark.toUpperCase())
                    }

                    setUnicodeConversion(
                        convertEncodingToUnicode(e.target.value)
                    )
                }}
            />
            <Text
                fontFamily="Estrangelo"
                fontSize="3xl"
                width="100%"
                style={{
                    direction: 'rtl',
                }}
            >
                {unicodeConversion}
            </Text>
        </VStack>
    )
}

export default Decoder
