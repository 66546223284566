import {
    Heading,
    HStack,
    Table,
    TableCaption,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast,
    VStack,
    Progress as ProgressBar,
    CircularProgress,
    CircularProgressLabel,
} from '@chakra-ui/react'
import { useCallback, useEffect, useReducer } from 'react'
import { supabase } from '../supabaseClient'

interface IBookEncodingStat {
    title: string
    book_name: string
    approved_count: number
    imported_count: number
}

interface IUserEncodedStat {
    first_name: string
    last_name: string
    encoded_count: number
    pending_approval_count: number
}

type State = {
    encodingStats: IBookEncodingStat[]
    statsEncodingsByUser: IUserEncodedStat[]
}

type Action =
    | { type: 'SET_ENCODING_STATS'; payload: IBookEncodingStat[] }
    | { type: 'SET_STATS_ENCODINGS_BY_USER'; payload: IUserEncodedStat[] }

const initialState: State = {
    encodingStats: [],
    statsEncodingsByUser: [],
}

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'SET_ENCODING_STATS':
            return { ...state, encodingStats: action.payload }
        case 'SET_STATS_ENCODINGS_BY_USER':
            return { ...state, statsEncodingsByUser: action.payload }
        default:
            return state
    }
}

const Progress = () => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const toast = useToast()
    const toastId = 'dashboard-nav-toast'

    const getEncodingStats = useCallback(async () => {
        const { data, error } = await supabase.from('stats_encoding').select()

        if (data) {
            dispatch({ type: 'SET_ENCODING_STATS', payload: data })
        }

        if (error && !toast.isActive(toastId)) {
            toast({
                id: toastId,
                title: 'Issue',
                description:
                    'Could not retrieve stats of what has already been encoded',
                status: 'error',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top',
            })
        }
    }, [toast])

    const getStatsEncodedByUser = useCallback(async () => {
        const { data, error } = await supabase.from('stats_by_user').select()

        if (data) {
            dispatch({ type: 'SET_STATS_ENCODINGS_BY_USER', payload: data })
        }

        if (error && !toast.isActive(toastId)) {
            toast({
                id: toastId,
                title: 'Issue',
                description:
                    'Could not retrieve user stats of what has already been encoded',
                status: 'error',
                duration: 3000,
                isClosable: true,
                variant: 'subtle',
                position: 'top',
            })
        }
    }, [toast])

    useEffect(() => {
        getEncodingStats()
        getStatsEncodedByUser()
    }, [getEncodingStats, getStatsEncodedByUser])

    return (
        <HStack alignItems="flex-start" width="100%">
            <VStack
                spacing="5"
                background="white"
                padding="24px"
                borderRadius="24px"
                minWidth={'container.md'}
                width="100%"
            >
                <Heading textAlign="center" color={'twitter.800'}>
                    Progress
                </Heading>
                <Heading color={'twitter.800'} fontSize="2xl">
                    Number of encoded verses
                </Heading>
                <TableContainer width="100%">
                    <Table variant="simple" size="sm">
                        <TableCaption>
                            How many verses have already been encoded
                        </TableCaption>
                        <Thead>
                            <Tr>
                                <Th>Source</Th>
                                <Th>Book</Th>
                                <Th>Done</Th>
                                <Th>Total</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {state.encodingStats.map((stat, statIndex) => {
                                const percentage = Math.ceil(
                                    (stat.approved_count /
                                        stat.imported_count) *
                                        100
                                )

                                return (
                                    <Tr key={`${stat.book_name}-${statIndex}`}>
                                        <Td>{stat.title}</Td>
                                        <Td>{stat.book_name}</Td>
                                        <Td>{stat.approved_count}</Td>
                                        <Td>{stat.imported_count}</Td>
                                        <Td>
                                            <CircularProgress
                                                value={percentage}
                                                color="#8fbc8f"
                                            >
                                                <CircularProgressLabel>
                                                    {percentage}%
                                                </CircularProgressLabel>
                                            </CircularProgress>
                                        </Td>
                                    </Tr>
                                )
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
                <Heading color={'twitter.800'} fontSize="2xl">
                    Leaderboard contributors
                </Heading>
                <TableContainer width="100%">
                    <Table variant="simple" size="sm">
                        <TableCaption>User stats</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>First name</Th>
                                <Th>Last name</Th>
                                <Th>Pending</Th>
                                <Th>Approved</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {state.statsEncodingsByUser.map(
                                (stat, statIndex) => (
                                    <Tr
                                        key={`${stat.first_name}-${stat.last_name}-${statIndex}`}
                                    >
                                        <Td>{stat.first_name}</Td>
                                        <Td>{stat.last_name}</Td>
                                        <Td>{stat.pending_approval_count}</Td>
                                        <Td>{stat.encoded_count}</Td>
                                    </Tr>
                                )
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>
        </HStack>
    )
}

export default Progress
