import {
    Divider,
    Heading,
    HStack,
    Tag,
    VStack,
    Text,
    Input,
    Modal,
    Button,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    useDisclosure,
    useMediaQuery,
    FormErrorMessage,
    FormControl,
    InputGroup,
    InputRightElement,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import axios from 'axios'
import React, { useCallback, useContext, useState } from 'react'
import { HiSearch } from 'react-icons/hi'
import { Field, FieldProps, Form, Formik } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'
import { ReaderActions, ReaderContext } from '../pages/Reader'
import TruncateText from './TruncatedText'

interface IETCBCLexiconLookupResponse {
    result: IETCBCLexiconLookupResultItem[]
    success: boolean
}

interface IETCBCLexiconLookupResultItem {
    lexeme: string
    matches: IETCBCLexiconLookupMatchItem[]
    success: boolean
}

interface IETCBCLexiconLookupMatchItem {
    lexeme: string
    properties: IETCBCLexiconLookupMatchProperties
    distance: number
}

interface IETCBCGlossLookupResponse {
    result: IETCBCGlossLookupResultItem[]
    success: boolean
}

interface IETCBCGlossLookupResultItem {
    query: string
    matches: IETCBCLexiconLookupMatchItem[]
    success: boolean
}

interface IETCBCLexiconLookupMatchProperties {
    sp: string // part-of-speech
    de?: string // Example values: "B<L", "Q@VOWL@>"
    gl: string // gloss
    ls?: string // Example values: "prop"
    st?: string // status
    gn?: string // gender
    vc?: string // Example values: "Q@VOWL"
}

const LookupContainer = styled(VStack)`
    width: 100%;

    ul {
        list-style-type: none;
    }
`

const LookupSchema = Yup.object().shape({
    q: Yup.string().required('Required'),
})

const Card = styled(VStack)`
    padding: 1rem;
    border-top: 1px solid rgb(226, 232, 240);
    border-left: 1px solid rgb(226, 232, 240);
    border-right: 1px solid rgb(226, 232, 240);
    border-bottom: 1px solid transparent;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
`

const EtcbcLexiconEntry: React.FC<{ match: IETCBCLexiconLookupMatchItem }> = ({
    match,
}) => {
    return (
        <Card as="ul" w={'100%'}>
            <HStack as="li" spacing={3} w="100%" justifyContent="center">
                <Tag colorScheme="pink" size="sm" mb="2">
                    {match.lexeme}
                </Tag>
            </HStack>
            <TruncateText text={match.properties.gl} maxLength={40} />
            <HStack as="li" spacing={3} w="100%" justifyContent="space-between">
                <Tag size="sm">Part-of-speech: </Tag>
                <Text fontSize="sm">{match.properties.sp}</Text>
            </HStack>
        </Card>
    )
}

const EtcbcLexicon: React.FC = () => {
    const [etcbcLexiconLookupResponse, setETCBCLexiconLookupResponse] =
        useState<IETCBCLexiconLookupResponse>()
    const [etcbcGlossLookupResponse, setETCBCGlossLookupResponse] =
        useState<IETCBCGlossLookupResponse>()
    const { isOpen, onClose } = useDisclosure()
    const [isLargerThanSm] = useMediaQuery('(min-width: 768px)')

    const initialValues: {
        q: string
        reference: { value: string; label: string }
    } = {
        q: etcbcLexiconLookupResponse?.result[0].lexeme || '',
        reference: { value: 'lexeme', label: 'Lexeme' },
    }

    const [formState, setFormState] = useState(initialValues)

    const { dispatch: readerDispatch } = useContext(ReaderContext)

    const getGlossData = useCallback(async (expr: string) => {
        const data = JSON.stringify({
            language: 'syriac',
            version: 'SSI',
            request: 'get_lexeme_by_property',
            mode: 'text',
            data: {
                property: 'gl',
                query: [expr],
                size: 4,
            },
        })

        const result = await axios.post<IETCBCGlossLookupResponse>(
            'https://etcbc.vu.nl/api/wla',
            data
        )
        setETCBCGlossLookupResponse(result.data)
    }, [])

    const getWordData = useCallback(async (word: string) => {
        try {
            const data = JSON.stringify({
                language: 'syriac',
                version: 'SSI',
                request: 'get_lexemes',
                mode: 'text',
                data: {
                    lexemes: [word],
                    size: 4,
                },
            })

            const res = await axios.post<IETCBCLexiconLookupResponse>(
                `https://etcbc.vu.nl/api/wla`,
                data
            )

            setETCBCLexiconLookupResponse(res.data)
        } catch (err) {
            setETCBCLexiconLookupResponse({} as IETCBCLexiconLookupResponse)
        }
    }, [])

    const referenceOptions = [
        {
            value: 'lexeme',
            label: 'Lexeme',
        },
        { value: 'gloss', label: 'Gloss' },
        { value: 'sedra', label: 'Sedra' },
    ]

    const LookupInfo = () => (
        <VStack
            as="ul"
            spacing="3"
            justifyContent={'flex-start'}
            alignItems="flex-start"
            listStyleType="none"
        >
            <li>
                <Heading color={'twitter.800'} fontSize="2xl">
                    References
                </Heading>
            </li>
            <Divider m={'16px 0'} />
            <Formik
                enableReinitialize={false}
                validationSchema={LookupSchema}
                initialValues={formState}
                onSubmit={(values) => {
                    setFormState({
                        q: values.q,
                        reference: values.reference,
                    })

                    if (values.reference.value === 'lexeme') {
                        getWordData(values.q)
                    }

                    if (values.reference.value === 'gloss') {
                        getGlossData(values.q)
                    }

                    if (values.reference.value === 'sedra') {
                        readerDispatch({
                            type: ReaderActions.LOOKUP_WORD,
                            dispatch: {
                                word: values.q,
                                timestamp: new Date().toString(),
                            },
                        })
                    }
                }}
            >
                {({ isSubmitting, values, setFieldValue }) => (
                    <Form>
                        <VStack>
                            <Field name="q">
                                {({
                                    field,
                                    form,
                                }: FieldProps<any, { q: string }>) => (
                                    <FormControl
                                        isInvalid={
                                            (form.errors.q &&
                                                form.touched.q) as boolean
                                        }
                                    >
                                        <InputGroup>
                                            <Input
                                                fontSize="sm"
                                                {...field}
                                                placeholder="Search"
                                            />
                                            <InputRightElement>
                                                <HiSearch />
                                            </InputRightElement>
                                        </InputGroup>
                                        <FormErrorMessage>
                                            {form.errors.q}
                                        </FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Select
                                styles={{
                                    container: (baseStyles) => ({
                                        ...baseStyles,
                                        width: '100%',
                                    }),
                                    control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor:
                                            'var(--chakra-colors-chakra-border-color)',
                                    }),
                                    singleValue: (baseStyles) => ({
                                        ...baseStyles,
                                        color: 'var(--chakra-colors-gray-500)',
                                        fontSize: '0.9rem',
                                    }),
                                }}
                                options={referenceOptions}
                                value={values.reference}
                                onChange={(option) =>
                                    setFieldValue('reference', option)
                                }
                            />

                            <Button
                                alignSelf={'flex-end'}
                                colorScheme="teal"
                                isLoading={isSubmitting}
                                type="submit"
                                size="sm"
                            >
                                Search
                            </Button>
                        </VStack>
                    </Form>
                )}
            </Formik>
            {formState.reference.value === 'gloss' &&
                etcbcGlossLookupResponse?.success &&
                etcbcGlossLookupResponse?.result.map((entry) => (
                    <VStack w={'100%'}>
                        {entry.matches.map((match) => (
                            <EtcbcLexiconEntry match={match} />
                        ))}
                    </VStack>
                ))}
            {formState.reference.value === 'gloss' &&
                etcbcGlossLookupResponse &&
                !etcbcGlossLookupResponse.success && (
                    <Text>No results found...</Text>
                )}
            {formState.reference.value === 'lexeme' &&
                etcbcLexiconLookupResponse?.success &&
                etcbcLexiconLookupResponse?.result.map((entry) => (
                    <VStack w={'100%'}>
                        {entry.matches.map((match) => (
                            <EtcbcLexiconEntry match={match} />
                        ))}
                    </VStack>
                ))}
            {formState.reference.value === 'lexeme' &&
                etcbcLexiconLookupResponse &&
                !etcbcLexiconLookupResponse.success && (
                    <Text>No results found...</Text>
                )}
            <Text
                fontSize={'xs'}
                color="gray.500"
                textAlign={'center'}
                w="100%"
            >
                Powered by ETCBC.NL
            </Text>
        </VStack>
    )

    return (
        <>
            {!isLargerThanSm && (
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalCloseButton />
                        <ModalBody>
                            <LookupInfo />
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}
            <LookupContainer
                display={{
                    base: 'none',
                    md: 'flex',
                }}
                background="white"
                padding="24px"
                borderRadius="24px"
                maxWidth={'238px'}
            >
                <LookupInfo />
            </LookupContainer>
        </>
    )
}

export default EtcbcLexicon
